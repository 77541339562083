import React, { useEffect, useState } from 'react';
import axios from 'axios';

const downloadAttachment = async (url, filename) => {
  const userString = localStorage.getItem('user');
  if (!userString) {
    console.error('No user found in localStorage');
    return;
  }

  const user = JSON.parse(userString);

  const authHeaders = {
    Authorization: `Bearer ${user.access_token}`,
    client: user.provider,
    expiry: user.expires_at,
    uid: user.uid,
  };

  try {
    const response = await axios.get(url, {
      headers: authHeaders,
      responseType: 'blob', // Set response type to 'blob'
    });

    if (response.status === 200) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } else {
      console.error('Unauthorized or failed to download attachment');
    }
  } catch (error) {
    console.error('Error downloading attachment:', error.response ? error.response.data : error.message);
  }
};

const getFullAttachmentUrl = (url) => {
  if (url.startsWith("http://localhost/")) {
    return url.replace("http://localhost/", "http://localhost:3001/");
  }
  return url;
};

const Attachment = ({ attachment }) => {
  const fullUrl = getFullAttachmentUrl(attachment.url);
  const [imageSrc, setImageSrc] = useState(null);
  const isImage = attachment.mime_type.startsWith('image/');
  const isPdf = attachment.mime_type === 'application/pdf';

  const fileIcon = (fileType) => {
    switch (fileType) {
      case 'application/pdf': return '📄';
      case 'image/jpeg':
      case 'image/jpg':
      case 'image/png':
        return '🖼️';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/msword': return '📝';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/vnd.ms-excel': return '📊';
      default: return '📁';
    }
  };

  const fetchImage = async (url) => { //For previewing images
    const userString = localStorage.getItem('user');
    if (!userString) {
      console.error('No user found in localStorage');
      return;
    }
  
    const user = JSON.parse(userString);
  
    const authHeaders = {
      Authorization: `Bearer ${user.access_token}`,
      client: user.provider,
      expiry: user.expires_at,
      uid: user.uid,
    };
  
    try {
      const response = await axios.get(url, {
        headers: authHeaders,
        responseType: 'blob',
      });
  
      if (response.status === 200) {
        const url = window.URL.createObjectURL(response.data);
        setImageSrc(url);
      } else {
        console.error('Unauthorized or failed to fetch image');
      }
    } catch (error) {
      console.error('Error fetching image:', error.response ? error.response.data : error.message);
    }
  };

  useEffect(() => {
    if (isImage) {
      fetchImage(fullUrl);
    }
  }, [fullUrl, isImage]);

  if (isImage) {
    return (
      <img
      src={imageSrc}
      alt={attachment.filename}
      className="attachment-preview"
      onClick={() => downloadAttachment(fullUrl, attachment.filename)}
      style={{ cursor: 'pointer', maxWidth: '60px', maxHeight: '60px' }}
    />
    );
  }

  if (isPdf) {
    return (
      <div>
        <button className="attachment-link" onClick={() => downloadAttachment(fullUrl, attachment.filename)}>
          {fileIcon(attachment.mime_type)} {attachment.filename}
        </button>
      </div>
    );
  }

  return (
    <button className="attachment-link" onClick={() => downloadAttachment(fullUrl, attachment.filename)}>
      {fileIcon(attachment.mime_type)} {attachment.filename}
    </button>
  );
};

export default Attachment;