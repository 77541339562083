import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Tooltip } from 'bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';

const loadingMessages = [
  "Analyzing email content...",
  "Generating smart reply...",
  "Almost there...",
  "Hang tight, working on it...",
  "Just a moment..."
];

const SmartReplyForm = ({ email, emailMessage: initialEmailMessage, showModal: initialShowModal }) => {
  const [subject, setSubject] = useState('');
  const [originalMessageId, setOriginalMessageID] = useState('');
  const [recipient, setRecipient] = useState('');
  const [emailMessage, setEmailMessage] = useState(initialEmailMessage || '');
  const [file, setFile] = useState(null);
  const [showModal, setShowModal] = useState(initialEmailMessage || false);
  const [autoReply, setAutoReply] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentEmailId, setCurrentEmailId] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState(loadingMessages[0]);
  const textareaRef = useRef(null);

  useEffect(() => {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
    return () => {
      tooltipList.forEach(tooltip => tooltip.dispose());
    };
  }, []);

  useEffect(() => {
    if (currentEmailId !== email.message_id) {
      setAutoReply('');
      setEmailMessage('');
      setCurrentEmailId(email.message_id);
    }
  }, [email]);

  useEffect(() => {
    if (showModal && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [showModal]);

  useEffect(() => {
    const handleReply = (event) => {
      if (event.key === 'r' && event.altKey === true ) { 
        if (!showModal) {
        openModal();} else {closeModal()}
      }
    };
    window.addEventListener('keydown', handleReply);
    return () => {
      window.removeEventListener('keydown', handleReply);
    };
  }, [showModal]); 

  useEffect(() => {
    let messageIndex = 0;
    const interval = setInterval(() => {
      if (loading) {
        messageIndex = (messageIndex + 1) % loadingMessages.length;
        setLoadingMessage(loadingMessages[messageIndex]);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [loading]);

  const openModal = async () => {
    setShowModal(true);
    setLoading(true);

    const cachedReply = sessionStorage.getItem(`autoReply-${email.message_id}`);
    if (cachedReply) {
      setAutoReply(cachedReply);
      setLoading(false);
      return;
    }

    try {
      const userString = localStorage.getItem('user');
      const user = JSON.parse(userString);

      const authHeaders = {
        'Authorization': `Bearer ${user.access_token}`,
        'Content-Type': 'application/json',
        'client': localStorage.getItem('client'),
        'expiry': localStorage.getItem('expiry'),
        'uid': user.uid,
      };

      const bodyContent = /<\/?[a-z][\s\S]*>/i.test(email.body) ? email.snippet : email.body;

      const response = await axios.post(`${process.env.REACT_APP_ROOT_PATH}api/v1/emails/replies`, {
        email: { body: bodyContent }
      }, {
        headers: authHeaders,
      });

      if (response.status === 200 && currentEmailId === email.message_id) {
        setAutoReply(response.data.body);
        sessionStorage.setItem(`autoReply-${email.message_id}`, response.data.body);
      }
    } catch (error) {
      console.error('Error fetching auto reply:', error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    } finally {
      if (currentEmailId === email.message_id) {
        setLoading(false);
      }
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setEmailMessage('');
  };

  const extractEmail = (from) => {
    if (from && from.includes('<') && from.includes('>')) {
      const startIndex = from.lastIndexOf('<') + 1;
      const endIndex = from.lastIndexOf('>');
      return from.substring(startIndex, endIndex);
    } else {
      return from;
    }
  };

  useEffect(() => {
    setRecipient(extractEmail(email.from));
    setSubject(`Re: ${email.subject}`);
    setOriginalMessageID(email.message_id);
    setEmailMessage(initialEmailMessage || '');
  }, [email, initialEmailMessage]);

  const handleUseAutoReply = () => {
    if (emailMessage === '') {
      setEmailMessage(autoReply);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);
  
    const authHeaders = {
      'Authorization': `Bearer ${user.access_token}`,
      'Content-Type': 'multipart/form-data',
      'client': localStorage.getItem('client'),
      'expiry': localStorage.getItem('expiry'),
      'uid': user.uid,
    };
    
    const formData = new FormData();
    formData.append('email[subject]', subject);
    formData.append('email[recipient]', recipient);
    formData.append('email[body]', emailMessage);
    formData.append('email[original_message_id]', originalMessageId);
    if (file) {
      formData.append('email[file]', file);
    }
    
    try {
      const response = await axios.post(`${process.env.REACT_APP_ROOT_PATH}api/v1/emails`, formData, {
        headers: authHeaders,
      });
      if (response.status === 201) { 
        setSubject('');
        setRecipient('');
        setEmailMessage('');
        setFile(null);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Your email has been sent',
          showConfirmButton: false,
          timer: 1500
        });
        setShowModal(false);
      }
    } catch (error) {
      console.error('Error sending email:', error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  const handleKeyDownOnTextArea = (e) => {
    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    } else if (e.key === 'Tab') {
      e.preventDefault();
      handleUseAutoReply();
    }
  };

  const handleImproveEmail = async () => {
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);

    const authHeaders = {
      'Authorization': `Bearer ${user.access_token}`,
      'Content-Type': 'application/json',
      'client': localStorage.getItem('client'),
      'expiry': localStorage.getItem('expiry'),
      'uid': user.uid,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_ROOT_PATH}api/v1/emails/improve`, {
        email: { body: emailMessage }
      }, {
        headers: authHeaders,
      });

      if (response.status === 200) {
        setEmailMessage(response.data.body);
      }
    } catch (error) {
      console.error('Error improving email:', error);
    }
  };

  const isReplyDisabled = /noreply|no-reply|ne-pas-repondre|invitation-do-not-reply|donotreply/i.test(extractEmail(email.from));

  return (
    <div className='reply'>
      <button className='square-button-gray' disabled={isReplyDisabled} onClick={openModal} data-bs-toggle="tooltip" data-bs-placement="top"
        data-bs-custom-class="custom-tooltip" data-bs-title={isReplyDisabled ? 'This email is a no-reply email' : 'Smart Reply [Alt + R]'}>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.83329 3.75002C4.45258 3.75002 3.33329 4.86931 3.33329 6.25002C3.33329 6.7239 3.46514 7.167 3.69416 7.5446C2.53935 7.76548 1.66663 8.78085 1.66663 10C1.66663 11.2192 2.53935 12.2345 3.69416 12.4554M5.83329 3.75002C5.83329 2.59943 6.76603 1.66669 7.91663 1.66669C9.06721 1.66669 9.99996 2.59943 9.99996 3.75002V16.25C9.99996 17.4006 9.06721 18.3334 7.91663 18.3334C6.76603 18.3334 5.83329 17.4006 5.83329 16.25C4.45258 16.25 3.33329 15.1308 3.33329 13.75C3.33329 13.2761 3.46514 12.833 3.69416 12.4554M5.83329 3.75002C5.83329 4.43161 6.16061 5.03675 6.66663 5.41684M3.69416 12.4554C3.99119 11.9657 4.45168 11.586 4.99996 11.3923" stroke="#101010" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M14.1667 16.25C15.5474 16.25 16.6667 15.1308 16.6667 13.75C16.6667 13.2761 16.5348 12.833 16.3058 12.4554C17.4606 12.2345 18.3333 11.2192 18.3333 10C18.3333 8.78085 17.4606 7.76548 16.3058 7.5446M14.1667 16.25C14.1667 17.4006 13.2339 18.3334 12.0833 18.3334C10.9327 18.3334 10 17.4006 10 16.25V3.75002C10 2.59943 10.9327 1.66669 12.0833 1.66669C13.2339 1.66669 14.1667 2.59943 14.1667 3.75002C15.5474 3.75002 16.6667 4.86931 16.6667 6.25002C16.6667 6.7239 16.5348 7.167 16.3058 7.5446M14.1667 16.25C14.1667 15.5684 13.8393 14.9633 13.3333 14.5832M16.3058 7.5446C16.0087 8.03436 15.5482 8.41394 15 8.60777" stroke="#101010" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>{/*brain icon */}

      </button>
      {showModal && (
        <div className="reply-modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <div className='reply-form-headers'>
              <h5>To: {extractEmail(email.from)}</h5>
              <h5>Subject: Re: {email.subject}</h5>
            </div>
            <form onSubmit={handleSubmit}>
              <textarea
                ref={textareaRef} 
                value={emailMessage} 
                onChange={(e) => setEmailMessage(e.target.value)} 
                placeholder={loading ? loadingMessage : (autoReply || "Hit Shift + Enter to send")}
                onKeyDown={handleKeyDownOnTextArea}             
                required 
              />
              <div className='reply-form-actions'>
                <button className='send-button' type="button" onClick={handleUseAutoReply}>
                  <FontAwesomeIcon icon="fa-solid fa-bolt" /> Use Suggestion
                </button>
                <div className="file-container">
                  <input 
                    type="file" 
                    id="fileInput" 
                    className="input-file" 
                    onChange={(e) => setFile(e.target.files[0])} 
                  />
                  <button className='send-button' type="button"><FontAwesomeIcon icon="fa-solid fa-paperclip" /> ATTACH FILE</button>
                </div>
                <button className='send-button' type="button" onClick={handleImproveEmail}><FontAwesomeIcon icon="fa-solid fa-wand-magic-sparkles" /> IMPROVE WITH AI</button>
                <button className='send-button' type="submit">SEND <FontAwesomeIcon icon="fa-solid fa-paper-plane" /></button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default SmartReplyForm;

