import React from 'react';

const Loader = () => {
  return( 
    <div className='loader'>
      <div className="cs-loader-inner">
        <label>●</label>
        <label>●</label>
        <label>●</label>
        <label>●</label>
        <label>●</label>
        <label>●</label>
      </div>
    </div>
)};

export default Loader;
