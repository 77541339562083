import React, { useRef, useState, useImperativeHandle, useEffect, forwardRef, useCallback } from 'react';
import axios from 'axios';
import DOMPurify from 'dompurify';
import SmartReplyForm from './SmartReplyForm';
import AddContactForm from './AddContactForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import ForwardForm from './ForwardForm';
import Attachment from './Attachment';
import Loader from './loader';

const deleteEmail = async (messageId, updateEmails, setSelectedEmailId) => {
  const userString = localStorage.getItem('user'); 
  const user = JSON.parse(userString);

  const authHeaders = {
    Authorization: `Bearer ${user.access_token}`,
    client: user.provider,
    expiry: user.expires_at,
    uid: user.uid,
  };

  const result = await Swal.fire({
    title: "Are you sure?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!"
  });

  if (result.isConfirmed) {
    try {
      const response = await axios.post(`${process.env.REACT_APP_ROOT_PATH}api/v1/emails/${messageId}/delete`, {}, {
        headers: authHeaders
      });
      if (response.status === 200) {
        console.log('Email deleted:', response.data);
        Swal.fire({
          title: "Deleted!",
          text: "Your email has been deleted.",
          icon: "success"
        });
        updateEmails(messageId, null);
        setSelectedEmailId(null);
      } else {
        console.error('Failed to delete email:', response.data);
        Swal.fire({
          title: "Error!",
          text: "Failed to delete email.",
          icon: "error"
        });
      }
    } catch (error) {
      console.error('Error deleting email:', error.response ? error.response.data : error.message);
      Swal.fire({
        title: "Error!",
        text: "An error occurred while deleting the email.",
        icon: "error"
      });
    }
  }
};

const starEmail = async (messageId, updateEmails) => {
  const userString = localStorage.getItem('user'); 
  const user = JSON.parse(userString);

  const authHeaders = {
    Authorization: `Bearer ${user.access_token}`,
    client: user.provider,
    expiry: user.expires_at,
    uid: user.uid,
  };
  try {
    const response = await axios.post(`${process.env.REACT_APP_ROOT_PATH}api/v1/emails/${messageId}/star`, {}, {
      headers: authHeaders
    });

    if (response.status === 200) {
      console.log('Starred email:', response.data);
      updateEmails(messageId, { starred: true });
    } else {
      console.error('Failed to star email:', response.data);
    }
  } catch (error) {
    console.error('Error starring email:', error.response ? error.response.data : error.message);
  }
};

const unstarEmail = async (messageId, updateEmails) => {
  const userString = localStorage.getItem('user'); 
  const user = JSON.parse(userString);

  const authHeaders = {
    Authorization: `Bearer ${user.access_token}`,
    client: user.provider,
    expiry: user.expires_at,
    uid: user.uid,
  };
  try {
    const response = await axios.post(`${process.env.REACT_APP_ROOT_PATH}api/v1/emails/${messageId}/unstar`, {}, {
      headers: authHeaders
    });

    if (response.status === 200) {
      console.log('Unstarred email:', response.data);
      updateEmails(messageId, { starred: false });
    } else {
      console.error('Failed to unstar email:', response.data);
    }
  } catch (error) {
    console.error('Error unstarring email:', error.response ? error.response.data : error.message);
  }
};

const markAsRead = async (messageId, updateEmails) => {
  const userString = localStorage.getItem('user'); 
  const user = JSON.parse(userString);

  const authHeaders = {
    Authorization: `Bearer ${user.access_token}`,
    client: user.provider,
    expiry: user.expires_at,
    uid: user.uid,
  };
  try {
    const response = await axios.post(`${process.env.REACT_APP_ROOT_PATH}api/v1/emails/${messageId}/mark_as_read`, {}, {
      headers: authHeaders
    });

    if (response.status === 200) {
      console.log('Marked as read:', response.data);
      updateEmails(messageId, { unread: false });
    } else {
      console.error('Failed to mark as read:', response.data);
    }
  } catch (error) {
    console.error('Error marking email as read:', error.response ? error.response.data : error.message);
  }
};

const EmailShow = forwardRef(({ emailContainerRef, email, updateEmails, setSelectedEmailId, isLoading, showCopilot }, ref) => {
  const iframeRef = useRef(null);
  const [isIframeFocused, setIsIframeFocused] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [summary, setSummary] = useState('');

  useImperativeHandle(ref, () => ({
    focusIframe: () => {
      if (iframeRef.current) {
        iframeRef.current.focus();
        setIsIframeFocused(true);
      }
    },
    blurIframe: () => {
      if (iframeRef.current) {
        iframeRef.current.blur();
        setIsIframeFocused(false);
      }
    }
  }));

  const formatBody = (body) => {
    // Replace all newlines with <br />
    return body.replace(/\r\n|\r|\n/g, '<br />');
  };

  const removeExcessBreaks = (body) => {
    return body.replace(/(<br>){3,}/g, '<br><br>');
  }

  useEffect(() => {
    if (email && email.body && iframeRef.current) {
      let bodyContent;
      if (email.body.includes('html')) {
        bodyContent = DOMPurify.sanitize(email.body);
      } else {
        bodyContent = DOMPurify.sanitize(formatBody(email.body));
      }
      bodyContent = removeExcessBreaks(bodyContent);
      
      const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
      iframeDocument.open();
      iframeDocument.write(`
        <style>
          body::-webkit-scrollbar {
            width: 8px;
          }
          body::-webkit-scrollbar-track {
            border-radius: 10px;
            margin: 10px 0;
            background: #ffffff;
            box-shadow: inset 6px 6px 12px #e6e6e6,
            inset -6px -6px 12px #ffffff;
          }
          body::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: #c7c7c7;
          }
          body::-webkit-scrollbar-thumb:hover {
            background: #b3b3b3;
          }
        </style>
        ${bodyContent}
      `);
      iframeDocument.close();

      const links = iframeDocument.querySelectorAll('a');
      links.forEach(link => link.setAttribute('target', '_blank'));
    }
  }, [email]);

  useEffect(() => {
    const markEmailAsRead = async () => {
      if (email && email.unread) {
        await markAsRead(email.message_id, updateEmails);
      }
    };
    markEmailAsRead();
  }, [email, updateEmails]);

  useEffect(() => {
    setSummary('');
    setShowSummary(false);
  }, [email]);

  useEffect(() => {
    const handleKeyIframeDown = (event) => {
      if (isIframeFocused && event.key === 'ArrowLeft') {
        ref.current.blurIframe();
        emailContainerRef.current.focus();
      }
    };
    const iframeDocument = iframeRef.current?.contentDocument || iframeRef.current?.contentWindow.document;
    iframeDocument?.addEventListener('keydown', handleKeyIframeDown);

    return () => {
      iframeDocument?.removeEventListener('keydown', handleKeyIframeDown);
    };
  }, [isIframeFocused]);

  if (!email) {
    return (<div className='email-show'>
      <div className={!showCopilot ? 'email-show-box-empty-extended glass' : 'email-show-box-empty glass'}>
        <p className='placeholder-icon'><FontAwesomeIcon icon="fa-solid fa-envelope" /></p>
        {isLoading && (
          <Loader/>
        )}
      </div>
    </div>);
  }

  return (
    <div className="email-show">
      <div className={!showCopilot ? 'email-show-box-extended' : 'email-show-box'}>
        <div className='email-show-actions'>
        {email.starred ? (
            <button className='square-button-gray-starred' onClick={() => unstarEmail(email.message_id, updateEmails, setSelectedEmailId)}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.4396 2.87017L12.9061 5.82742C13.1061 6.23908 13.6394 6.63393 14.0894 6.70954L16.7474 7.15481C18.4472 7.44046 18.8472 8.68385 17.6223 9.91043L15.5559 11.9939C15.2059 12.3468 15.0143 13.0273 15.1225 13.5146L15.7142 16.0938C16.1808 18.1353 15.1059 18.925 13.3145 17.858L10.823 16.371C10.3731 16.1022 9.63154 16.1022 9.17321 16.371L6.68185 17.858C4.89871 18.925 3.8155 18.1269 4.28212 16.0938L4.87372 13.5146C4.98203 13.0273 4.79039 12.3468 4.44043 11.9939L2.37399 9.91043C1.15746 8.68385 1.54908 7.44046 3.24889 7.15481L5.90693 6.70954C6.34855 6.63393 6.88183 6.23908 7.0818 5.82742L8.54829 2.87017C9.34821 1.26553 10.648 1.26553 11.4396 2.87017Z" stroke="#101010" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>{/*Star icon */}
            </button>
          ) : (
            <button className='square-button-gray' onClick={() => starEmail(email.message_id, updateEmails, setSelectedEmailId)}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.4396 2.87017L12.9061 5.82742C13.1061 6.23908 13.6394 6.63393 14.0894 6.70954L16.7474 7.15481C18.4472 7.44046 18.8472 8.68385 17.6223 9.91043L15.5559 11.9939C15.2059 12.3468 15.0143 13.0273 15.1225 13.5146L15.7142 16.0938C16.1808 18.1353 15.1059 18.925 13.3145 17.858L10.823 16.371C10.3731 16.1022 9.63154 16.1022 9.17321 16.371L6.68185 17.858C4.89871 18.925 3.8155 18.1269 4.28212 16.0938L4.87372 13.5146C4.98203 13.0273 4.79039 12.3468 4.44043 11.9939L2.37399 9.91043C1.15746 8.68385 1.54908 7.44046 3.24889 7.15481L5.90693 6.70954C6.34855 6.63393 6.88183 6.23908 7.0818 5.82742L8.54829 2.87017C9.34821 1.26553 10.648 1.26553 11.4396 2.87017Z" stroke="#101010" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>{/*Star icon */}
            </button>
          )}
          <button className='square-button-gray' onClick={() => deleteEmail(email.message_id, updateEmails, setSelectedEmailId)}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.25 4.58331L15.7336 12.9376C15.6016 15.072 15.5357 16.1392 15.0007 16.9066C14.7361 17.2859 14.3956 17.6061 14.0006 17.8466C13.2017 18.3333 12.1325 18.3333 9.99392 18.3333C7.8526 18.3333 6.78192 18.3333 5.98254 17.8457C5.58733 17.6047 5.24667 17.284 4.98223 16.904C4.4474 16.1355 4.38287 15.0667 4.25384 12.9293L3.75 4.58331" stroke="#101010" strokeWidth="1.25" strokeLinecap="round"/>
              <path d="M2.5 4.58335H17.5M13.3797 4.58335L12.8109 3.4098C12.433 2.63024 12.244 2.24045 11.9181 1.99736C11.8458 1.94344 11.7693 1.89547 11.6892 1.85394C11.3283 1.66669 10.8951 1.66669 10.0287 1.66669C9.14067 1.66669 8.69667 1.66669 8.32973 1.86179C8.24842 1.90503 8.17082 1.95494 8.09774 2.011C7.76803 2.26394 7.58386 2.66798 7.21551 3.47607L6.71077 4.58335" stroke="#101010" strokeWidth="1.25" strokeLinecap="round"/>
              <path d="M7.91663 13.75V8.75" stroke="#101010" strokeWidth="1.25" strokeLinecap="round"/>
              <path d="M12.0834 13.75V8.75" stroke="#101010" strokeWidth="1.25" strokeLinecap="round"/>
            </svg>{/*Trash icon */}
          </button>
          <SmartReplyForm email={email}/>
          <ForwardForm email={email} />
        </div>
        <div className='email-content'>
          <div className='email-show-header'>
            <div>
              <h2>{email.subject}</h2>
            </div>
            <div className='email-show-sender'>
              <h4>From: {email.from}</h4>
              <AddContactForm contact={email.from}/>
            </div>
            <h4 className='email-show-receiver'>To: {email.to}</h4>
          </div>
          <iframe
            ref={iframeRef}
            style={{ width: '100%', height: '100%', border: 'none' }}
            title="Email Content"
            tabIndex="0"
          />
          {email.attachments && email.attachments.length > 0 && (
            <div className="email-attachments">
              {email.attachments.map((attachment, index) => (
                <Attachment key={index} attachment={attachment} />
              ))}
            </div>
          )}
        </div>
        
      </div>
    </div>
  );
});

export default React.memo(EmailShow);