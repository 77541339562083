import React, { useState, useEffect, useContext } from 'react';
import { HashRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Contacts from './components/Contacts';
import Home from './components/Home';
import Sidebar from './components/Sidebar';
import { AuthProvider } from './context/AuthContext';
import AuthContext from './context/AuthContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import EmailsList from './components/EmailsList';
import SentEmailsList from './components/SentEmailsList';
import Copilot from './components/Copilot';
import 'animate.css';
import { SelectedEmailProvider } from './context/SelectedEmailContext';

library.add(fas);

const AppWrapper = () => (
  <Router>
    <AuthProvider>
      <SelectedEmailProvider>
        <App />
      </SelectedEmailProvider>
    </AuthProvider>
  </Router>
);

const App = () => {
  const location = useLocation();
  const [filter, setFilter] = useState(new URLSearchParams(location.search).get('filter') || 'All');
  const [showCopilot, setShowCopilot] = useState(true);
  const { isLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    setFilter(new URLSearchParams(location.search).get('filter') || 'All');
  }, [location]);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
      <div className='App'>
        {isLoggedIn && <Sidebar setFilter={setFilter} />}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/emails" element={<EmailsList showCopilot={showCopilot} filter={filter} />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/sent" element={<SentEmailsList showCopilot={showCopilot} />} />
        </Routes>
        {isLoggedIn && <Copilot showCopilot={showCopilot} setShowCopilot={setShowCopilot} />}
      </div>
    </GoogleOAuthProvider>
  );
}

export default AppWrapper;
