import React, { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import Welcome from './Welcome';
import EmailsList from './EmailsList';
// Landing page on webflow
const Home = () => {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <div>
      {isLoggedIn ? <EmailsList /> : <Welcome />}
    </div>
  );
};

export default Home;
