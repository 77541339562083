import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext({
  user: null,
  isLoggedIn: false,
  login: () => { },
  logout: () => { }
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const login = (userData) => {
    try {
      setUser(userData);
      localStorage.setItem('user', JSON.stringify(userData));
    } catch (error) {
      console.error("Failed to save user:", error);
    }
  };

  const logout = () => {
    try {
      setUser(null);
      localStorage.removeItem('user');
      localStorage.removeItem('authToken');
    } catch (error) {
      console.error("Failed to clear user data:", error);
    }
  };

  useEffect(() => {
    try {
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        setUser(JSON.parse(storedUser));
      }
    } catch (error) {
      console.error("Failed to load user:", error);
    }
  }, []);

  // Optional: Add a storage event listener for session synchronization across tabs
  useEffect(() => {
    const syncLogout = (event) => {
      if (event.key === 'logout') {
        console.log('Logged out from storage!');
        setUser(null); // Sync logout across tabs
      }
    };
    window.addEventListener('storage', syncLogout);
    return () => window.removeEventListener('storage', syncLogout);
  }, []);

  return (
    <AuthContext.Provider value={{ user, isLoggedIn: !!user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
