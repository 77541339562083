import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';

const ContactsEmailForm = ({ contact, closeModal }) => {
  const [emailSubject, setEmailSubject] = useState('');
  const [emailMessage, setEmailMessage] = useState('');
  const [file, setFile] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userString = localStorage.getItem('user'); // Retrieve the JSON string
    const user = JSON.parse(userString);

    const authHeaders = {
      'Authorization': `Bearer ${user.access_token}`,
      'Content-Type': 'multipart/form-data',
      'client': localStorage.getItem('client'),
      'expiry': localStorage.getItem('expiry'),
      'uid': user.uid,
    };

    const formData = new FormData();
    formData.append('email[subject]', emailSubject);
    formData.append('email[recipient]', contact.email_address);
    formData.append('email[body]', emailMessage);
    if (file) {
      formData.append('email[file]', file);
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_ROOT_PATH}api/v1/emails`, formData, {
        headers: authHeaders,
      });
      if (response.status === 201) {
        setEmailSubject('');
        setEmailMessage('');
        setFile(null);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Your email has been sent',
          showConfirmButton: false,
          timer: 1500
        });
        closeModal();
      }
    } catch (error) {
      console.error('Error sending email:', error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  const handleImproveEmail = async () => {
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);

    const authHeaders = {
      Authorization: `Bearer ${user.access_token}`,
      'Content-Type': 'application/json',
      client: localStorage.getItem('client'),
      expiry: localStorage.getItem('expiry'),
      uid: user.uid,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_ROOT_PATH}api/v1/emails/improve`, {
        email: { body: emailMessage },
      }, { headers: authHeaders });

      if (response.status === 200) {
        setEmailMessage(response.data.body);
      }
    } catch (error) {
      console.error('Error improving email:', error);
    }
  };


  const handleKeyDownOnTextArea = (e) => {
    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <div className="email-form-modal">
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="close" onClick={closeModal}>&times;</span>
        <form onSubmit={handleSubmit}>
          <div className='reply-form-headers'>
            <div className='form-headers-input'>
              <label htmlFor='emailRecipient'>To:</label>
              <input
                id='emailRecipient'
                type="email"
                value={contact.email_address}
                readOnly
                className='email-input'
              />
            </div>
            <div className='form-headers-input'>
              <label htmlFor='emailSubject'>Subject:</label>
              <input
                id='emailSubject'
                type="text"
                value={emailSubject}
                onChange={(e) => setEmailSubject(e.target.value)}
                placeholder="Subject"
                required
                className='subject-input'
              />
            </div>
          </div>
          <textarea
            value={emailMessage}
            onChange={(e) => setEmailMessage(e.target.value)}
            placeholder="Hit Shift + Enter to send"
            onKeyDown={handleKeyDownOnTextArea}
            required
          />
          <div className='reply-form-actions'>
            <button className='send-button' type='button'><FontAwesomeIcon icon='fa-solid fa-paperclip' /> ATTACH FILE</button>
            <button className='send-button' type='button' onClick={handleImproveEmail}><FontAwesomeIcon icon='fa-solid fa-wand-magic-sparkles' /> SMART IMPROVE</button>
            <button className='send-button' type='submit'>SEND <FontAwesomeIcon icon='fa-solid fa-paper-plane' /></button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactsEmailForm;
