import React from 'react';
import Login from './login';

const Welcome = () => {
  return (
    <div className='welcome-div'>
      <div className='banner'>
        <h1>BigMails</h1>
        {/* <img id="email-png" src="/assets/mail-notif.png" alt="email" /> */}
        <div className='login-section animate__animated animate__fadeIn'>
          <Login />
        </div>
      </div>
    </div>
  );
};

export default Welcome;