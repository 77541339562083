import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Tooltip } from 'bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';

const extractContactDetails = (contact) => {
  let email = contact;
  let firstName = '';
  let lastName = '';

  if (contact && contact.includes('<') && contact.includes('>')) {
    const startIndex = contact.lastIndexOf('<') + 1;
    const endIndex = contact.lastIndexOf('>');
    email = contact.substring(startIndex, endIndex);
  }

  const namePart = contact.split('<')[0].trim();
  const nameParts = namePart.split(' ');

  if (nameParts.length > 1) {
    firstName = capitalizeFirstLetter(nameParts[0]);
    lastName = capitalizeFirstLetter(nameParts[nameParts.length - 1]);
  } else if (nameParts.length === 1) {
    firstName = capitalizeFirstLetter(nameParts[0]);
  }

  return { email, firstName, lastName };
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const AddContactForm = ({ contact, setContacts }) => {
  const modalContentRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [contactDetails, setContactDetails] = useState({
    email: '',
    firstName: '',
    lastName: '',
    company: '',
    position: '',
    nickname: '',
    profilePicture: null
  });

  const [previewImage, setPreviewImage] = useState('/assets/gray-avatar.png');

  const handleClickOutside = (event) => {
    if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
      setShowModal(false);
    }
  };

  useEffect(() => {
    if (showModal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showModal]);

  useEffect(() => {
    if (contact) {
      const details = extractContactDetails(contact);
      setContactDetails((prevDetails) => ({
        ...prevDetails,
        email: details.email || '',
        firstName: details.firstName || '',
        lastName: details.lastName || '',
      }));
    } else {
      setContactDetails((prevDetails) => ({
        ...prevDetails,
        email: '',
        firstName: '',
        lastName: '',
      }));
    }
  }, [contact]);

  useEffect(() => {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
    return () => {
      tooltipList.forEach(tooltip => tooltip.dispose());
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'a' && event.altKey) {
        setShowModal(prevShowModal => !prevShowModal);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);

    const authHeaders = {
      'Authorization': `Bearer ${user.access_token}`,
      'Content-Type': 'multipart/form-data',
      'client': localStorage.getItem('client'),
      'expiry': localStorage.getItem('expiry'),
      'uid': user.uid,
    };

    const formData = new FormData();
    formData.append('contact[email_address]', contactDetails.email);
    formData.append('contact[first_name]', contactDetails.firstName);
    formData.append('contact[last_name]', contactDetails.lastName);
    formData.append('contact[company]', contactDetails.company);
    formData.append('contact[position]', contactDetails.position);
    formData.append('contact[nickname]', contactDetails.nickname);

    if (contactDetails.profilePicture) {
      formData.append('contact[photo]', contactDetails.profilePicture);
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_ROOT_PATH}api/v1/contacts`, formData, {
        headers: authHeaders,
      });
      if (response.status === 201) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: `${contactDetails.firstName} has been added to your contacts`,
          showConfirmButton: false,
          timer: 1700
        });
        if (setContacts) {
          setContacts(prevContacts => [...prevContacts, response.data.contact]);
        }
        setShowModal(false);
      }
    } catch (error) {
      console.error('Error adding contact:', error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value
    }));
  };


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setContactDetails((prevDetails) => ({
      ...prevDetails,
      profilePicture: file
    }));
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className='add-contact'>
      <button className='button-sm-dark' onClick={() => setShowModal(true)} data-bs-toggle="tooltip" data-bs-placement="top"
        data-bs-custom-class="custom-tooltip" data-bs-title='Add to contacts'>
        <img src="assets/add-user.png" alt="Add contact icon" />
      </button>
      {showModal && (
        <div className="modal">
          <div className="modal-content glass-main-color" ref={modalContentRef}>
            <span className="close" onClick={() => setShowModal(false)}>&times;</span>
            <form onSubmit={handleSubmit}>
              <div className='add-contact-avatar'>
                <label className='file-container'>
                  <img src={previewImage} alt="Contact" />
                  <input
                    type="file"
                    className='input-file'
                    name="profilePicture"
                    onChange={handleFileChange}
                    accept="image/*"
                  />
                </label>
              </div>
              <div className='add-contact-container'>
                <input
                  type="text"
                  name="email"
                  value={contactDetails.email}
                  onChange={handleInputChange}
                  placeholder="Email"
                  required
                />
                <input
                  type="text"
                  name="firstName"
                  placeholder="First name"
                  value={contactDetails.firstName}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last name"
                  value={contactDetails.lastName}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="text"
                  name="company"
                  placeholder="Company"
                  value={contactDetails.company}
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="position"
                  placeholder="Position"
                  value={contactDetails.position}
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="nickname"
                  placeholder="Nickname"
                  value={contactDetails.nickname}
                  onChange={handleInputChange}
                />
              </div>
              <div className='add-contact-button'>
                <button className='square-button' type="submit"><FontAwesomeIcon icon="fa-solid fa-check" /></button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddContactForm;