
import axios from 'axios';

export const writeEmail = async (inputText, setRecipient, setSubject, setEmailMessage,
    setMessages, setShowEmailForm, setIsThinking, isSwitchOn) => {

    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);

    const authHeaders = {
        Authorization: `Bearer ${user.access_token}`,
        'Content-Type': 'application/json',
        client: localStorage.getItem('client'),
        expiry: localStorage.getItem('expiry'),
        uid: user.uid,
    };

    try {
        const response = await axios.post(`${process.env.REACT_APP_ROOT_PATH}api/v1/emails/autowrite`, {
            email: { body: inputText },
        }, { headers: authHeaders });

        if (response.status === 200) {
            setRecipient(response.data.to || '');
            setSubject(response.data.subject || '');
            setEmailMessage(response.data.body || '');
            setMessages((messages) => [...messages, { author: 'ARIA', text: `${isSwitchOn ? 'Et voila!' : 'Here you go.'}` }]);
            setShowEmailForm(true);
            const nextMessage = { author: 'ARIA', text: `${isSwitchOn ? 'Souhaitez-vous que je rédige un autre e-mail pour vous?' : 'Would you like me to compose another email for you?'}` };
            setTimeout(() => setMessages((messages) => [...messages, nextMessage]), 3000);
        }
    } catch (error) {
        console.error('Error writing email:', error);
        setMessages((messages) => [...messages, { author: 'ARIA', text: 'Apologies, my system encountered an issue. Our devs will fix it.' }]);
    } finally {
        setIsThinking(false);
    }
};

export const replyToEmail = async (email, inputText, setMessages, isSwitchOn, setShowReplyForm,
    setEmailMessage, setIsThinking) => {
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);

    const authHeaders = {
        Authorization: `Bearer ${user.access_token}`,
        'Content-Type': 'application/json',
        client: localStorage.getItem('client'),
        expiry: localStorage.getItem('expiry'),
        uid: user.uid,
    };

    try {
        const response = await axios.post(`${process.env.REACT_APP_ROOT_PATH}api/v1/emails/reply_with_prompt`, {
            email: { body: email.body, prompt: inputText },
        }, { headers: authHeaders });

        if (response.status === 200) {
            setEmailMessage(response.data.body || '');
            console.log(response.data.body);
            setMessages((messages) => [...messages, { author: 'ARIA', text: `${isSwitchOn ? 'Et voila!' : 'Here you go.'}` }]);
            setShowReplyForm(true);
        }
    } catch (error) {
        console.error('Error writing email:', error);
        setMessages((messages) => [...messages, { author: 'ARIA', text: 'Apologies, my system encountered an issue. Our devs will fix it.' }]);
    } finally {
        setIsThinking(false);
    }
};

export const summarizeEmail = async (emailBody, setMessages, setIsThinking) => {
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);

    const authHeaders = {
        Authorization: `Bearer ${user.access_token}`,
        'Content-Type': 'application/json',
        client: localStorage.getItem('client'),
        expiry: localStorage.getItem('expiry'),
        uid: user.uid,
    };

    try {
        const response = await axios.post(`${process.env.REACT_APP_ROOT_PATH}api/v1/emails/summarize_email`, {
            email: { body: emailBody },
        }, { headers: authHeaders });

        if (response.status === 200) {
            console.log(response.data)
            const nextMessage = { author: 'ARIA', text: response.data.body };
            setTimeout(() => setMessages((messages) => [...messages, nextMessage]), 3000);
        }
    } catch (error) {
        console.error('Error writing email:', error);
        setMessages((messages) => [...messages, { author: 'ARIA', text: 'Apologies, my system encountered an issue while summarizing this email. Our devs will fix it.' }]);
    } finally {
        setIsThinking(false);
    }
};

export const extractContactDetails = (contact) => {
    let email = contact;
    let name = '';

    if (contact && contact.includes('<') && contact.includes('>')) {
        const startIndex = contact.lastIndexOf('<') + 1;
        const endIndex = contact.lastIndexOf('>');
        email = contact.substring(startIndex, endIndex);
    }

    name = contact.split('<')[0].trim();

    return { email, name };
};

export const extractEmail = (from) => {
    if (from && from.includes('<') && from.includes('>')) {
        const startIndex = from.lastIndexOf('<') + 1;
        const endIndex = from.lastIndexOf('>');
        return from.substring(startIndex, endIndex);
    } else {
        return from;
    }
};