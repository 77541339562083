import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Tooltip } from 'bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';

const EditContactForm = ({ contact }) => {
  const [showModal, setShowModal] = useState(false);
  const [previewImage, setPreviewImage] = useState(contact.photo_url || '/assets/avatar.jpg');
  const [contactDetails, setContactDetails] = useState({
    email: contact.email_address || "",
    firstName: contact.first_name || "",
    lastName: contact.last_name || "",
    company: contact.company || "",
    position: contact.position || "",
    nickname: contact.nickname || "",
    profilePicture: null
  });

  useEffect(() => {
    setContactDetails({
      email: contact.email_address || "",
      firstName: contact.first_name || "",
      lastName: contact.last_name || "",
      company: contact.company || "",
      position: contact.position || "",
      nickname: contact.nickname || "",
      profilePicture: null
    });
    setPreviewImage(contact.photo_url || '/assets/avatar.jpg');
  }, [contact]);

  useEffect(() => {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
    return () => {
      tooltipList.forEach(tooltip => tooltip.dispose());
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);

    const authHeaders = {
      'Authorization': `Bearer ${user.access_token}`,
      'Content-Type': 'multipart/form-data',
      'client': localStorage.getItem('client'),
      'expiry': localStorage.getItem('expiry'),
      'uid': user.uid,
    };

    const formData = new FormData();
    formData.append('contact[email_address]', contactDetails.email);
    formData.append('contact[first_name]', contactDetails.firstName);
    formData.append('contact[last_name]', contactDetails.lastName);
    formData.append('contact[company]', contactDetails.company);
    formData.append('contact[position]', contactDetails.position);
    formData.append('contact[nickname]', contactDetails.nickname);

    if (contactDetails.profilePicture) {
      formData.append('contact[photo]', contactDetails.profilePicture);
    }

    try {
      const response = await axios.patch(`${process.env.REACT_APP_ROOT_PATH}api/v1/contacts/${contact.id}`, formData, {
        headers: authHeaders,
      });
      if (response.status === 200) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: `${contactDetails.firstName} has been updated`,
          showConfirmButton: false,
          timer: 1700
        });
        closeModal();
      }
    } catch (error) {
      console.error('Error editing contact:', error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setContactDetails((prevDetails) => ({
      ...prevDetails,
      profilePicture: file
    }));
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className='add-contact'>
      <button className='button-sm' onClick={openModal}>
        <FontAwesomeIcon icon="fa-solid fa-gear" />
      </button>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <form onSubmit={handleSubmit}>
              <div className='add-contact-avatar'>
                <label className='file-container'>
                  <img src={previewImage} alt="Contact" />
                  <input
                    type="file"
                    className='input-file'
                    name="profilePicture"
                    onChange={handleFileChange}
                    accept="image/*"
                  />
                </label>
              </div>
              <div className='add-contact-container'>
                <input
                  type="text"
                  name="email"
                  value={contactDetails.email}
                  onChange={handleInputChange}
                  placeholder="Email"
                  required
                />
                <input
                  type="text"
                  name="firstName"
                  placeholder="First name"
                  value={contactDetails.firstName}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last name"
                  value={contactDetails.lastName}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="text"
                  name="company"
                  placeholder="Company"
                  value={contactDetails.company}
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="position"
                  placeholder="Position"
                  value={contactDetails.position}
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="nickname"
                  placeholder="Nickname"
                  value={contactDetails.nickname}
                  onChange={handleInputChange}
                />
              </div>
              <button className='btn' type="submit">SAVE</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditContactForm;
