import React, { useEffect, useState, useRef, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import EmailShow from './EmailShow';
import { SelectedEmailContext } from '../context/SelectedEmailContext';

const fetchAllEmails = async (user, setEmails, setIsLoading, setUnreadEmailsCount, setNextPageToken, setTotalEmails) => {
  const authHeaders = {
    'Authorization': `Bearer ${user.access_token}`,
    'client': localStorage.getItem('client'),
    'expiry': localStorage.getItem('expiry'),
    'uid': user.uid,
  };

  const url = `${process.env.REACT_APP_ROOT_PATH}api/v1/emails/`;

  try {
    setIsLoading(true);
    const response = await axios.get(url, { headers: authHeaders });
    const fetchedEmails = response.data.messages || [];
    const cachedEmails = JSON.parse(localStorage.getItem('emails')) || [];
    const areEmailsDifferent = JSON.stringify(fetchedEmails) !== JSON.stringify(cachedEmails);
    setNextPageToken(response.data.next_page_token);
    const totalEmails = response.data.total_emails
    if (areEmailsDifferent) {
      setEmails(fetchedEmails);
      setUnreadEmailsCount(fetchedEmails.filter(email => email.unread).length);
      localStorage.setItem('emails', JSON.stringify(fetchedEmails));
    }
    const storedTotalEmails = parseInt(localStorage.getItem('totalEmails'), 10);
    if (storedTotalEmails !== totalEmails) {
      localStorage.setItem('totalEmails', totalEmails.toString());
      setTotalEmails(totalEmails);
    }
  } catch (error) {
    console.error('Error fetching emails:', error);
  } finally {
    setIsLoading(false);
  }
};

const fetchNewEmails = async (user, setEmails, setUnreadEmailsCount, setSelectedIndex, selectedIndex, setTotalEmails) => {
  const authHeaders = {
    'Authorization': `Bearer ${user.access_token}`,
    'client': localStorage.getItem('client'),
    'expiry': localStorage.getItem('expiry'),
    'uid': user.uid,
  };

  const url = `${process.env.REACT_APP_ROOT_PATH}api/v1/emails/new_emails_broadcast`;

  try {
    const response = await axios.get(url, { headers: authHeaders });
    const newEmails = response.data.new_messages || [];
    const storedEmails = JSON.parse(localStorage.getItem('emails')) || [];
    const storedEmailIds = storedEmails.map(email => email.message_id);
    const emailsToAdd = newEmails.filter(email => !storedEmailIds.includes(email.message_id));
    const totalEmails = response.data.total_emails;
    if (emailsToAdd.length > 0) {
      let updatedEmails = [...emailsToAdd, ...storedEmails];
      updatedEmails = updatedEmails.sort((a, b) => new Date(b.date) - new Date(a.date));
      localStorage.setItem('emails', JSON.stringify(updatedEmails));
      setUnreadEmailsCount(updatedEmails.filter(email => email.unread).length);
      setEmails(updatedEmails);
      setSelectedIndex(selectedIndex + emailsToAdd.length);
    }
    const storedTotalEmails = parseInt(localStorage.getItem('totalEmails'), 10);
    if (storedTotalEmails !== totalEmails) {
      localStorage.setItem('totalEmails', totalEmails.toString());
      setTotalEmails(totalEmails);
    }
  } catch (error) {
    console.error('Error fetching emails:', error);
  }
};

const loadMoreEmails = async (user, emails, setEmails, nextPageToken, setNextPageToken, setIsLoading) => {
  if (!nextPageToken) return;
  const authHeaders = {
    'Authorization': `Bearer ${user.access_token}`,
    'client': localStorage.getItem('client'),
    'expiry': localStorage.getItem('expiry'),
    'uid': user.uid,
  };

  console.log("Loading more emails")
  const url = `${process.env.REACT_APP_ROOT_PATH}api/v1/emails/load_next_emails`;
  
  try {
    const response = await axios.get(url, { headers: authHeaders, params: { page_token: nextPageToken } });
    const fetchedEmails = response.data.messages || [];

    setEmails([...emails, ...fetchedEmails]);
    localStorage.setItem('emails', JSON.stringify([...emails, ...fetchedEmails]));
    setNextPageToken(response.data.next_page_token);
  } catch (error) {
    console.error('Error loading more emails:', error);
  }
};

const EmailsList = ({ showCopilot = true, filter }) => {
  const [emails, setEmails] = useState([]);
  const [totalEmails, setTotalEmails] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const emailContainerRef = useRef(null);
  const emailShowRef = useRef(null);
  const [unreadEmailsCount, setUnreadEmailsCount] = useState(0);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [selectedEmailId, setSelectedEmailId] = useState(null);
  const { selectedEmail, setSelectedEmail } = useContext(SelectedEmailContext);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return `${months[date.getMonth()]} ${date.getDate()}`;
  };

  useEffect(() => {
    const userString = localStorage.getItem('user');
    if (userString) {
      const user = JSON.parse(userString);
      const cachedEmails = localStorage.getItem('emails');
      if (cachedEmails) {
        setEmails(JSON.parse(cachedEmails));
      } else {
        fetchAllEmails(user, setEmails, setIsLoading, setUnreadEmailsCount, setNextPageToken, setTotalEmails);
      }
    }
  }, []);

  useEffect(() => {
    const userString = localStorage.getItem('user');
    if (userString) {
      const user = JSON.parse(userString);
      const interval = setInterval(() => {
        fetchNewEmails(user, setEmails, setUnreadEmailsCount, setSelectedIndex, selectedIndex, setTotalEmails);
      }, 5000); // Fetch emails every 5 seconds
      return () => clearInterval(interval);
    }
  }, [selectedIndex]);

  useEffect(() => {
    const userString = localStorage.getItem('user');
    if (userString) {
      const user = JSON.parse(userString);
      const interval = setInterval(() => {
        fetchAllEmails(user, setEmails, setIsLoading, setUnreadEmailsCount, setNextPageToken);
      }, 600000); // Fetch emails every 10 minutes
      return () => clearInterval(interval);
    }
  }, []);

  const handleScroll = () => {
    if (emailContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = emailContainerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        const userString = localStorage.getItem('user');
        if (userString) {
          console.log("Bottom reached")
          const user = JSON.parse(userString);
          loadMoreEmails(user, emails, setEmails, nextPageToken, setNextPageToken, setIsLoading);
        }
      }
    }
  };

  useEffect(() => {
    const emailContainer = emailContainerRef.current;
    if (emailContainer) {
      emailContainer.addEventListener('scroll', handleScroll);
      return () => emailContainer.removeEventListener('scroll', handleScroll);
    }
  }, [emails, nextPageToken]);

  const filteredEmails = emails.filter(email => {
    if (filter === 'All') return true;
    if (filter === 'Starred') return email.starred;
    if (filter === 'Important') return email.important;
    return true;
  }).filter(email =>
    email.from.toLowerCase().includes(query.toLowerCase()) ||
    email.subject.toLowerCase().includes(query.toLowerCase())
  );

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowDown' && document.activeElement === emailContainerRef.current) {
        setSelectedIndex(prevIndex => Math.min(prevIndex + 1, filteredEmails.length - 1));
      } else if (event.key === 'ArrowUp' && document.activeElement === emailContainerRef.current) {
        setSelectedIndex(prevIndex => Math.max(prevIndex - 1, 0));
      } else if (event.key === 'ArrowRight') {
        emailShowRef.current.focusIframe();
      } else if (event.key === 'ArrowLeft') {
        emailContainerRef.current.focus();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [filteredEmails]);

  useEffect(() => {
    if (selectedIndex >= 0 && selectedIndex < filteredEmails.length) {
      setSelectedEmail(filteredEmails[selectedIndex]);
    }
  }, [selectedIndex, filteredEmails]);

  const handleEmailClick = (emailId, index) => {
    const selectedEmail = emails.find(email => email.message_id === emailId);
    setSelectedEmail(selectedEmail);
    setSelectedIndex(index);
    emailContainerRef.current.focus();
  };

  const updateEmails = (messageId, changes) => {
    setEmails(prevEmails => {
      let updatedEmails;
      if (changes === null) {
        updatedEmails = prevEmails.filter(email => email.message_id !== messageId);
      } else {
        updatedEmails = prevEmails.map(email =>
          email.message_id === messageId ? { ...email, ...changes } : email
        );
      }
      localStorage.setItem('emails', JSON.stringify(updatedEmails));
      setUnreadEmailsCount(updatedEmails.filter(email => email.unread).length);
      return updatedEmails;
    });
  };

  const removeSurroundingQuotes = (str) => {
    if (str.length >= 2 &&
      ((str[0] === "'" && str[str.length - 1] === "'") ||
        (str[0] === '"' && str[str.length - 1] === '"'))) {
      return str.slice(1, -1);
    }
    return str;
  };

  const extractNameFromEmail = (email) => {
    const match = email.match(/(.*)<(.*)>/);
    let name;
    if (match) {
      name = match[1].trim();
    } else {
      name = email.trim();
    }

    // If no name, uses plain email address
    if (!name) {
      name = email.trim();
    } else {
      // Use removeSurroundingQuotes to remove starting quotes
      name = removeSurroundingQuotes(name);
      // Remove multiple spaces
      name = name.replace(/\s+/g, ' ');
    }
    return name;
  };

  useEffect(() => {
    const fetchRecipients = async () => {
      const userString = localStorage.getItem('user');
      if (userString) {
        const user = JSON.parse(userString);
        const url = `${process.env.REACT_APP_ROOT_PATH}api/v1/recipients`;
        try {
          const response = await axios.get(url, { headers: { "uid": user.uid } });
          localStorage.setItem('recipients', JSON.stringify(response.data.recipients));
        } catch (error) {
          console.error('Error fetching recipients:', error);
        }
      }
    };
    fetchRecipients();
  }, []);

  return (
    <div className='emailsIndex'>
      {unreadEmailsCount > 0 && (
        <p className='unread-notif'>{unreadEmailsCount}</p>
      )}
      <div className='emailslist'>
        <div className='emails-box'>
          <div className='emails-count'>
            {emails.length} out of {localStorage.getItem('totalEmails')}
          </div>
          <div className="inbox-searchbar">
            <form onSubmit={e => e.preventDefault()} className="inbox-search-form">
              <FontAwesomeIcon icon={faSearch} style={{ color: '#A3A9B3' }} />
              <input
                type="text"
                className="inbox-search-input"
                placeholder="Search Inbox"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </form>
          </div>
          <div
            className="email-container hidden-scrollbar"
            ref={emailContainerRef}
            tabIndex="0"
          >
            {filteredEmails.length > 0 ? (
              filteredEmails.slice(0, 200).map((email, index) => (
                <div
                  className={`email ${email.unread ? 'unread' : ''} ${index === selectedIndex ? 'selected' : ''}`}
                  key={email.message_id}
                  onClick={() => handleEmailClick(email.message_id, index)}
                >
                  <div className="email-info">
                    <div className="email__header">
                      <span className={`email__status-dot ${email.unread ? 'unread' : 'read'}`}></span>
                      <p className="email__sender">{extractNameFromEmail(email.from)}</p>
                      {email.labels && <p className={`email__labels ${email.labels}`}>{email.labels}</p>}
                    </div>
                    {email.snippet ? (
                      <p className="email__subject">{email.snippet}</p>
                    ) : (
                      <p className="email__subject">{email.subject}</p>
                    )}
                    <div className='email__tags'>
                      {email.starred && <p style={{ color: '#ffab00', fontSize: '12px' }}><FontAwesomeIcon icon="fa-solid fa-star" /></p>}
                      {email.attachments && email.attachments.length > 0 && <p className="attachment-icon" style={{ color: '#8b8b8b', fontSize: '12px' }}><FontAwesomeIcon icon="fa-solid fa-paperclip" /></p>}
                      <p className="email__date">{formatDate(email.date)}</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className='no-email'>{isLoading ? "Loading emails..." : "No emails to display."}</p>
            )}
          </div>
        </div>
      </div>
      {selectedEmail ? (
        <EmailShow
          ref={emailShowRef}
          emailContainerRef={emailContainerRef}
          email={selectedEmail}
          updateEmails={updateEmails}
          setSelectedEmailId={setSelectedEmailId}
          showCopilot={showCopilot}
        />
      ) : <EmailShow isLoading={isLoading} />}
    </div>
  );
};

export default EmailsList;
