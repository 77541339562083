import React, { useContext, useState, useEffect } from 'react';
import { useGoogleLogin, googleLogout } from '@react-oauth/google';
import AuthContext from '../context/AuthContext';
import { useNavigate } from "react-router-dom";
import { Dropdown } from 'bootstrap';
import ThemeModal from './ThemeModal';

const Login = ({ inNavbar }) => {
  const { user, login, logout, isLoggedIn } = useContext(AuthContext);
  const [loginStatus, setLoginStatus] = useState('');
  const [isThemeModalOpen, setIsThemeModalOpen] = useState(false);
  const navigate = useNavigate();
  const defaultAvatar2 = 'https://cdn-icons-png.flaticon.com/512/6388/6388000.png';
  const [themeColor, setThemeColor] = useState(() => {
    return localStorage.getItem('themeColor') || 'rgb(15, 53, 74)';
  });

  const changeColor = (color) => {
    setThemeColor(color);
    localStorage.setItem('themeColor', color);
  };

  useEffect(() => {
    if (themeColor === "#f0f8ffe6") {
      document.documentElement.style.setProperty('--primary-color', "#ffffff");
      document.documentElement.style.setProperty('--secondary-color', "#444444e8");
      document.documentElement.style.setProperty('--secondary-color-alpha-50', "#4444448a");
      document.documentElement.style.setProperty('--primary-color-alpha-50', "#f0f8ff84");
      document.documentElement.style.setProperty('--highlight-color', "#0051FE");
      document.documentElement.style.setProperty('--highlight-color-alpha-20', "#f7faff");
      document.documentElement.style.setProperty('--secondary-text-color', "aliceblue");
      document.documentElement.style.setProperty('--stroke-color', "#000");
    } else {
    document.documentElement.style.setProperty('--primary-color', `rgb(${themeColor})`);
    document.documentElement.style.setProperty('--secondary-color', "aliceblue");
    document.documentElement.style.setProperty('--secondary-color-alpha-50', "rgba(240, 248, 255, 0.504)");
    document.documentElement.style.setProperty('--primary-color-alpha-50', `rgba(${themeColor},0.5)`);
    document.documentElement.style.setProperty('--highlight-color', `rgb(${themeColor})`);
    document.documentElement.style.setProperty('--secondary-text-color', "#363636");
    document.documentElement.style.setProperty('--stroke-color', "#fff");
  }}, [themeColor]);

  useEffect(() => {
    var dropdownElementList = [].slice.call(document.querySelectorAll('.dropdown-toggle'));
    var dropdownList = dropdownElementList.map(function (dropdownToggleEl) {
      return new Dropdown(dropdownToggleEl);
    });
  }, []);

  const googleLogin = useGoogleLogin({
    onSuccess: tokenResponse => handleCredentialResponse(tokenResponse),
    onError: error => {
      console.log('Login Failed:', error);
      setLoginStatus('Login failed. Please try again.');
    },
    scope: 'email profile https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.modify',
    flow: 'auth-code',
  });

  const clearEmailCacheOnLogout = () => {
    localStorage.removeItem('emails');
    localStorage.removeItem('cachedEmails');
    localStorage.removeItem('lastFetch');
  };

  const logOut = () => {
    googleLogout();
    logout();
    clearEmailCacheOnLogout();
    setLoginStatus('');
    navigate("/") // redirect to landing page upon logout
  };

  const handleCredentialResponse = async (response) => {
    try {
      const backendResponse = await fetch(`${process.env.REACT_APP_ROOT_PATH}api/v1/google_auth/callback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ code: response.code }) // Send authorization code to backend
      });

      if (!backendResponse.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await backendResponse.json();

      if (data.status === 'SUCCESS') {
        login(data.user);
        setLoginStatus('Login successful!');
        navigate("/") // redirect to inbox upon login
      } else {
        setLoginStatus(data.message || 'Login failed.');
      }
    } catch (error) {
      console.error("Failed to fetch and process data:", error);
      setLoginStatus('Login failed: ' + error.message);
    }
  };

  if (inNavbar && isLoggedIn) return null;

  return (
    <div>
      <ThemeModal isOpen={isThemeModalOpen} onClose={() => setIsThemeModalOpen(false)} changeColor={changeColor} />
      {!isLoggedIn ? (
        <button className='nav-signin' onClick={googleLogin}>
          <img src="assets/google_logo.png" alt="Google Logo" className='auth-logo' />Sign in with Google
        </button>
      ) : (
        <div className="nav-item dropdown">
          <button className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            <img src={user.avatar_url || defaultAvatar2} alt="User Avatar" style={{ borderRadius: '50%', width: '35px' }}
              onError={(e) => { e.target.onerror = null; e.target.src = defaultAvatar2; }} />
          </button>
          <ul className="dropdown-menu dropdown-menu-end">
            <li><button disabled className="dropdown-item">My Profile</button></li>
            <li><button disabled className="dropdown-item">Add another account</button></li>
            <li><button className="dropdown-item" onClick={() => setIsThemeModalOpen(true)}>Change theme</button></li>
            <li>
              <button className="dropdown-item" onClick={logOut}>Log out</button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default Login;
