import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import EmailForm from './EmailForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'bootstrap';
import { Link } from 'react-router-dom';
import Login from './login';

const Sidebar = ({ setFilter }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const initializeTooltips = () => {
      const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
      const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl);
      });
      return tooltipList;
    };
    const tooltipList = initializeTooltips();
    return () => {
      tooltipList.forEach(tooltip => tooltip.dispose());
    };
  }, []);

  const handleEmailOptionClick = (filter) => {
    setFilter(filter);
    navigate(`/emails?filter=${filter}`);
  };

  return (
    <div className='sidebar'>
      <div className='new-email'>
        <EmailForm />
      </div>
      <div className='email-options'>
        <button className='button-sm button-sm-icons button-sm-icons-sidebar' data-bs-toggle="tooltip" data-bs-placement="right" data-bs-custom-class="custom-tooltip" data-bs-title='Inbox' onClick={() => handleEmailOptionClick('All')}>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className='email-options__icon'>
            <path d="M2.08337 9.99998C2.08337 6.26803 2.08337 4.40205 3.24274 3.24268C4.40212 2.08331 6.26809 2.08331 10 2.08331C13.732 2.08331 15.598 2.08331 16.7574 3.24268C17.9167 4.40205 17.9167 6.26803 17.9167 9.99998C17.9167 13.7319 17.9167 15.5979 16.7574 16.7573C15.598 17.9166 13.732 17.9166 10 17.9166C6.26809 17.9166 4.40212 17.9166 3.24274 16.7573C2.08337 15.5979 2.08337 13.7319 2.08337 9.99998Z" stroke="#606776" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.9167 11.25H13.812C13.1102 11.25 12.5589 11.8363 12.2496 12.456C11.9136 13.1292 11.2408 13.75 10 13.75C8.75929 13.75 8.08648 13.1292 7.75049 12.456C7.44122 11.8363 6.88985 11.25 6.18809 11.25H2.08337" stroke="#606776" strokeWidth="1.25" strokeLinejoin="round" />
          </svg>
        </button>
        <button className='button-sm button-sm-icons button-sm-icons-sidebar' data-bs-toggle="tooltip" data-bs-placement="right" data-bs-custom-class="custom-tooltip" data-bs-title='Important' onClick={() => handleEmailOptionClick('Important')}>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99996 18.3334C14.6023 18.3334 18.3333 14.6024 18.3333 10C18.3333 5.39765 14.6023 1.66669 9.99996 1.66669C5.39759 1.66669 1.66663 5.39765 1.66663 10C1.66663 14.6024 5.39759 18.3334 9.99996 18.3334Z" stroke="#606776" strokeWidth="1.25" />
            <path d="M9.99329 12.5H10.0008" stroke="#606776" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 10V6.66669" stroke="#606776" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
        <button className='button-sm button-sm-icons button-sm-icons-sidebar' data-bs-toggle="tooltip" data-bs-placement="right" data-bs-custom-class="custom-tooltip" data-bs-title='Starred' onClick={() => handleEmailOptionClick('Starred')}>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.4396 2.87017L12.9061 5.82742C13.1061 6.23908 13.6394 6.63393 14.0894 6.70954L16.7474 7.15481C18.4472 7.44046 18.8472 8.68385 17.6223 9.91043L15.5559 11.9939C15.2059 12.3468 15.0143 13.0273 15.1225 13.5146L15.7142 16.0938C16.1808 18.1353 15.1059 18.925 13.3145 17.858L10.823 16.371C10.3731 16.1022 9.63154 16.1022 9.17321 16.371L6.68185 17.858C4.89871 18.925 3.8155 18.1269 4.28212 16.0938L4.87372 13.5146C4.98203 13.0273 4.79039 12.3468 4.44043 11.9939L2.37399 9.91043C1.15746 8.68385 1.54908 7.44046 3.24889 7.15481L5.90693 6.70954C6.34855 6.63393 6.88183 6.23908 7.0818 5.82742L8.54829 2.87017C9.34821 1.26553 10.648 1.26553 11.4396 2.87017Z" stroke="#606776" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
        <Link to="/sent" className='button-sm button-sm-icons button-sm-icons-sidebar' data-bs-toggle="tooltip" data-bs-placement="right" data-bs-custom-class="custom-tooltip" data-bs-title='Sent'>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5398 2.54409C15.7248 0.589447 2.07211 5.37764 2.08338 7.12581C2.09616 9.10823 7.41511 9.71806 8.88937 10.1317C9.77596 10.3804 10.0134 10.6354 10.2178 11.5651C11.1436 15.7754 11.6085 17.8696 12.6679 17.9163C14.3565 17.991 19.3111 4.45164 17.5398 2.54409Z" stroke="#606776" strokeWidth="1.25" />
            <path d="M9.58337 10.4167L12.5 7.5" stroke="#606776" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
          </svg>

        </Link>
      </div>
      <div className='sidebar-navigation'>
        <Link to="/contacts" className='button-sm button-sm-icons button-sm-icons-sidebar' data-bs-toggle="tooltip" data-bs-placement="right" data-bs-custom-class="custom-tooltip" data-bs-title='Contacts'>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.75 8.33335C3.75 5.19065 3.75 3.61931 4.72631 2.643C5.70262 1.66669 7.27397 1.66669 10.4167 1.66669H11.6667C14.8093 1.66669 16.3807 1.66669 17.357 2.643C18.3333 3.61931 18.3333 5.19065 18.3333 8.33335V11.6667C18.3333 14.8094 18.3333 16.3808 17.357 17.357C16.3807 18.3334 14.8093 18.3334 11.6667 18.3334H10.4167C7.27397 18.3334 5.70262 18.3334 4.72631 17.357C3.75 16.3808 3.75 14.8094 3.75 11.6667V8.33335Z" stroke="#606776" strokeWidth="1.25" />
            <path d="M3.74996 5H1.66663M3.74996 10H1.66663M3.74996 15H1.66663" stroke="#606776" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.729 7.07719C12.729 7.99764 11.9829 8.74385 11.0625 8.74385C10.142 8.74385 9.39587 7.99764 9.39587 7.07719C9.39587 6.15675 10.142 5.41058 11.0625 5.41058C11.9829 5.41058 12.729 6.15675 12.729 7.07719Z" stroke="#606776" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.76647 13.0967C8.64846 11.739 10.049 11.2301 11.0624 11.2311C12.0758 11.2322 13.4353 11.739 14.3172 13.0967C14.3743 13.1846 14.39 13.2926 14.3385 13.3839C14.1323 13.7496 13.4919 14.4754 13.0293 14.5246C12.4979 14.5811 11.1075 14.5891 11.0635 14.5893C11.0193 14.5891 9.58612 14.5811 9.05437 14.5246C8.59187 14.4754 7.95146 13.7496 7.7452 13.3839C7.69374 13.2926 7.70945 13.1846 7.76647 13.0967Z" stroke="#606776" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </Link>
      </div>

      <div className='login-logout'>
        <Login />
      </div>
    </div>
  );
};

export default Sidebar;