import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Tooltip } from 'bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';

const EmailForm = ({
  subject: initialSubject = '',
  recipient: initialRecipient = '',
  emailMessage: initialEmailMessage = '',
  setSubject = () => {},
  setRecipient = () => {},
  setEmailMessage = () => {},
  setShowEmailForm = () => {},
  showEmailForm = false,
  onClose,
}) => {
  const [file, setFile] = useState(null);
  const [showModal, setShowModal] = useState(showEmailForm);
  const [subject, updateSubject] = useState(initialSubject);
  const [recipient, updateRecipient] = useState(initialRecipient);
  const [emailMessage, updateEmailMessage] = useState(initialEmailMessage);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    updateSubject(initialSubject);
  }, [initialSubject]);

  useEffect(() => {
    updateRecipient(initialRecipient);
  }, [initialRecipient]);

  useEffect(() => {
    updateEmailMessage(initialEmailMessage);
  }, [initialEmailMessage]);

  const openModal = () => setShowModal(true);

  const closeModal = () => {
    handleClose()
    updateEmailMessage("")
    updateRecipient("")
    updateSubject("")
    setShowSuggestions(false);
    setShowModal(false)
  };

  useEffect(() => {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    const tooltipList = tooltipTriggerList.map((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl));
    return () => {
      tooltipList.forEach((tooltip) => tooltip.dispose());
    };
  }, []);

  const handleRecipientChange = (e) => {
    const inputValue = e.target.value;
    updateRecipient(inputValue);
    setRecipient(inputValue);
    if (inputValue.length > 0) {
      const storedRecipients = JSON.parse(localStorage.getItem('recipients')) || [];
      const filteredSuggestions = storedRecipients.filter(recipient =>
        recipient.email_address.toLowerCase().includes(inputValue.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleSubjectFocus = () => {
    setShowSuggestions(false);
  };

  const handleSuggestionClick = (suggestion) => {
    updateRecipient(suggestion.email_address);
    setRecipient(suggestion.email_address);
    setShowSuggestions(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);

    const authHeaders = {
      Authorization: `Bearer ${user.access_token}`,
      'Content-Type': 'multipart/form-data',
      client: localStorage.getItem('client'),
      expiry: localStorage.getItem('expiry'),
      uid: user.uid,
    };

    const formData = new FormData();
    formData.append('email[subject]', subject);
    formData.append('email[recipient]', recipient);
    formData.append('email[body]', emailMessage);
    if (file) {
      formData.append('email[file]', file);
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_ROOT_PATH}api/v1/emails`, formData, {
        headers: authHeaders,
      });
      if (response.status === 201) {
        setShowEmailForm(false);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Your email has been sent',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      console.error('Error sending email:', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      });
    }
    if (onClose) onClose();
  };

  const handleClose = () => {
    if (onClose) onClose();
  }

  const handleImproveEmail = async () => {
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);

    const authHeaders = {
      Authorization: `Bearer ${user.access_token}`,
      'Content-Type': 'application/json',
      client: localStorage.getItem('client'),
      expiry: localStorage.getItem('expiry'),
      uid: user.uid,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_ROOT_PATH}api/v1/emails/improve`, {
        email: { body: emailMessage },
      }, { headers: authHeaders });

      if (response.status === 200) {
        updateEmailMessage(response.data.body);
      }
    } catch (error) {
      console.error('Error improving email:', error);
    }
  };

  const handleKeyDownOnTextArea = (e) => {
    if (e.key === 'Enter' && e.altKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <div className='new-email-form'>
      <button
        className='square-button'
        onClick={openModal}
        data-bs-toggle='tooltip'
        data-bs-placement='top'
        data-bs-custom-class='custom-tooltip'
        data-bs-title='New Mail'
      >
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.99996 6.66669V13.3334M13.3333 10H6.66663" stroke="#606776" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M18.3333 10C18.3333 5.39765 14.6023 1.66669 9.99996 1.66669C5.39758 1.66669 1.66663 5.39765 1.66663 10C1.66663 14.6024 5.39758 18.3334 9.99996 18.3334C14.6023 18.3334 18.3333 14.6024 18.3333 10Z" stroke="#606776" strokeWidth="1.25"/>
        </svg>
      </button>
      {showModal && (
        <div className='email-form-modal'>
          <div className='modal-content'>
            <span className='close' onClick={closeModal}>&times;</span>
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div className='reply-form-headers'>
                <div className='form-headers-input'>
                  <label htmlFor='emailRecipient' style={{ marginRight: '5px' }}>To:</label>
                  <input
                    id='emailRecipient'
                    type='email'
                    autoComplete='new-password'
                    value={recipient}
                    onChange={handleRecipientChange}
                    required
                  />
                  {showSuggestions && suggestions.length > 0 && (
                    <ul className='suggestions-list'>
                      {suggestions.map((suggestion, index) => (
                        <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                          {suggestion.email_address}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <div className='form-headers-input'>
                  <label htmlFor='emailSubject' style={{ marginRight: '5px' }}>Subject:</label>
                  <input
                    id='emailSubject'
                    type='text'
                    value={subject}
                    onFocus={handleSubjectFocus}
                    onChange={(e) => {
                      updateSubject(e.target.value);
                      setSubject(e.target.value);
                    }}
                    required
                    autoComplete='new-password'
                  />
                </div>
              </div>
              <textarea
                value={emailMessage}
                onFocus={handleSubjectFocus}
                onChange={(e) => {
                  updateEmailMessage(e.target.value);
                  setEmailMessage(e.target.value);
                }}
                placeholder='Compose email here'
                onKeyDown={handleKeyDownOnTextArea}
              />
              <div className='reply-form-actions'>
                <div className="file-container">
                  <input 
                    type="file" 
                    id="fileInput" 
                    className="input-file" 
                    onChange={(e) => setFile(e.target.files[0])} 
                  />
                  <button className='send-button' type="button"><FontAwesomeIcon icon="fa-solid fa-paperclip" /> ATTACH FILE</button>
                </div>
                <button className='send-button' type='button' onClick={handleImproveEmail}><FontAwesomeIcon icon='fa-solid fa-wand-magic-sparkles' /> SMART IMPROVE</button>
                <button className='send-button' type='submit'>SEND <FontAwesomeIcon icon='fa-solid fa-paper-plane' /></button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailForm;