import React, { useState, useRef, useEffect, useContext } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EmailForm from './EmailForm';
import { SelectedEmailContext } from '../context/SelectedEmailContext';
import SmartReplyForm from './SmartReplyForm';
import { Exception } from 'sass';
import { writeEmail, extractEmail, replyToEmail, summarizeEmail } from './CopilotTools';

const Copilot = ({ showCopilot, setShowCopilot }) => {
  const [subject, setSubject] = useState('');
  const [recipient, setRecipient] = useState('');
  const [emailMessage, setEmailMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [isSwitchOn, setIsSwitchOn] = useState(() => {
    return localStorage.getItem('isSwitchOn') === 'true';
  });
  const chatareaRef = useRef(null);
  const { selectedEmail } = useContext(SelectedEmailContext);
  const [isThinking, setIsThinkingInternal] = useState(false);
  let willThink = false; // Use let if you need to reassign it
  const setIsThinking = (value) => {
    setIsThinkingInternal(value);
    willThink &= value;
  };

  const toggleCopilot = () => setShowCopilot(!showCopilot);

  const handleInputChange = (event) => setInputText(event.target.value);

  const handleInstruction = async (event) => {
    event.preventDefault();
    const prompt = inputText;
    const userMessage = { author: 'user', text: inputText };
    setMessages((messages) => [...messages, userMessage]);

    setInputText('');
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);

    willThink = true;
    setTimeout(() => { setIsThinking(willThink) }, 200);

    const authHeaders = {
      Authorization: `Bearer ${user.access_token}`,
      'Content-Type': 'application/json',
      client: localStorage.getItem('client'),
      expiry: localStorage.getItem('expiry'),
      uid: user.uid,
    };

    try {
      const response = await axios.get(`${process.env.REACT_APP_ROOT_PATH}api/v1/instruction`, {
        params: { body: prompt },
      }, { headers: authHeaders });

      if (response.status === 200) {
        console.log(response.data);
        const instruction = response.data.instruction.toLowerCase();
        switch (instruction) {
          case 'reply':
            if (selectedEmail) {
              const isReplyDisabled = /noreply|no-reply|ne-pas-repondre|invitation-do-not-reply|donotreply/i.test(extractEmail(selectedEmail.from));
              if (!isReplyDisabled) {
                replyToEmail(selectedEmail, prompt, setMessages, isSwitchOn, setShowReplyForm, setEmailMessage, setIsThinking);
              } else {
                setIsThinking(false);
                setMessages((messages) => [...messages, { author: 'ARIA', text: 'This is a no-reply email.' }]);
              }
            } else {
              setIsThinking(false);
              setMessages((messages) => [...messages, { author: 'ARIA', text: 'Please select an email to reply to.' }]);
            }
            break;
          case 'write':
          case 'say':
            writeEmail(prompt, setRecipient, setSubject, setEmailMessage, setMessages, setShowEmailForm, setIsThinking, isSwitchOn);
            break;
          case 'summarize':
          case 'summary':
            if (selectedEmail) {
              summarizeEmail(selectedEmail.body, setMessages, setIsThinking);
            } else {
              setIsThinking(false);
              setMessages((messages) => [...messages, { author: 'ARIA', text: 'Please select an email to summarize.' }]);
            }
            break
          case 'other':
            setIsThinking(false);
            setMessages((messages) => [...messages, { author: 'ARIA', text: 'Apologies, I am still unable to do that.' }]);
            break;
          default:
            throw new Exception(`Instruction was not recognized: '${instruction}'.`);
        }
      }
    } catch (error) {
      setIsThinking(false);
      console.error('Error detecting instruction:', error);
      setMessages((messages) => [...messages, { author: 'ARIA', text: 'Apologies, my system encountered an issue. Our devs will fix it.' }]);
    } finally {
    }
  };

  const handleCloseEmailForm = () => {
    setShowEmailForm(false);
    setSubject('');
    setRecipient('');
    setEmailMessage('');
  };

  const handleSwitchChange = () => {
    const newSwitchState = !isSwitchOn;
    setIsSwitchOn(newSwitchState);
    localStorage.setItem('isSwitchOn', newSwitchState);
  };

  useEffect(() => {
    if (showCopilot && chatareaRef.current) {
      chatareaRef.current.focus();
    }
  }, [showCopilot]);

  useEffect(() => {
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);
    const fr_welcome = `Bonjour, ${user.first_name}! Que puis-je écrire pour vous aujourd'hui?`;
    const en_welcome = `Hi, ${user.first_name}! What email may I write for you today?`;
    const welcomeMessage = { author: 'ARIA', text: isSwitchOn ? fr_welcome : en_welcome };
    const timer = setTimeout(() => {
      setMessages([welcomeMessage]);
    }, 1000);

    return () => clearTimeout(timer);
  }, [isSwitchOn]);

  return (
    <div className='copilot-container'>
      <div className='copilot-btn animate__animated animate__fadeIn'>
        <button className='copilot-button glass' type="button" onClick={toggleCopilot}>
          <FontAwesomeIcon icon={showCopilot ? "fa-solid fa-chevron-right" : "fa-solid fa-chevron-left"} />
        </button>
      </div>
      <div className={`copilot animate__animated ${showCopilot ? 'animate__slideInRight' : 'animate__slideOutRight'}`}>
        <div className='chat-header'>
          {/* <label className="switch">
            <input type="checkbox" checked={isSwitchOn} onChange={handleSwitchChange}/>
            <span className="slider round"></span>
          </label> */}
          <a className="brand" href="/">
            <img src="assets/logo.png" alt="BigMails Logo" />
          </a>
        </div>
        <div className='chat-box'>
          {messages.map((msg, index) => (
            <div className={msg.author === 'user' ? 'output-left' : 'output-right'} key={index}>
              <p className='animate__animated animate__zoomIn'>{msg.text}</p>
            </div>
          ))}
          {isThinking && (
            <div className='output-right' display={isThinking.toString()}>
              <p className='animate__animated animate__zoomIn'>Hold on
                <span className="dot">.</span>
                <span className="dot">.</span>
                <span className="dot">.</span>
              </p>
            </div>
          )}
        </div>
        <form className='input' onSubmit={handleInstruction}>
          <div className='copilot-submit'>
            <input
              ref={chatareaRef}
              type="text"
              placeholder='Message your virtual assistant'
              value={inputText}
              onChange={handleInputChange}
            />
            <button className='copilot-submit-button' type="submit"><FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" /></button>
          </div>
        </form>
      </div>
      {showEmailForm && (
        <EmailForm
          subject={subject}
          recipient={recipient}
          emailMessage={emailMessage}
          setSubject={setSubject}
          setRecipient={setRecipient}
          setEmailMessage={setEmailMessage}
          setShowEmailForm={setShowEmailForm}
          showEmailForm={showEmailForm}
          onClose={handleCloseEmailForm}
        />
      )}
      {showReplyForm && (
        <SmartReplyForm
          email={selectedEmail}
          emailMessage={emailMessage}
          showModal={true}
        />
      )}
    </div>
  );
};

export default Copilot;
