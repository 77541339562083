import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ThemeModal = ({ isOpen, onClose, changeColor }) => {
  const themeRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (themeRef.current && !themeRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="theme-modal" style={{ display: 'block' }}>
      <div className="theme-modal-content glass-white" ref={themeRef}>
        <div className="close">
          <button className="button-sm" onClick={onClose}><FontAwesomeIcon icon="fa-solid fa-xmark" /></button>
        </div>
        <div className='theme-buttons'>       
          <button className='theme-button'
            style={{ backgroundColor: 'rgb(56 57 58)' }}
            onClick={() => changeColor("56, 57, 58")}
          >
          </button>
          <button className='theme-button'
            style={{ backgroundColor: 'rgb(15, 53, 74)' }}
            onClick={() => changeColor("15, 53, 74")}
          >
          </button>
          <button className='theme-button'
            style={{ backgroundColor: 'rgb(52, 85, 75)' }}
            onClick={() => changeColor("52, 85, 75")}
          >
          </button>
          <button className='theme-button'
            style={{ backgroundColor: '#3b2354' }}
            onClick={() => changeColor("59, 35, 84")}
          >
          </button>
          <button className='theme-button'
            style={{ backgroundColor: '#4f2023' }}
            onClick={() => changeColor("79, 32, 35")}
          >
          </button>
        </div>
        {/* lighter options */}
        <div className='theme-buttons'>
          <button className='theme-button'
            style={{ backgroundColor: 'rgb(146 152 160)' }}
            onClick={() => changeColor("#f0f8ffe6")}
          >
          </button>
          <button className='theme-button'
            style={{ backgroundColor: '#5d8d9e' }}
            onClick={() => changeColor("93, 141, 158")}
          >
          </button>
          <button className='theme-button'
            style={{ backgroundColor: '#7a9b8e' }}
            onClick={() => changeColor("122, 155, 142")}
          >
          </button>
          <button className='theme-button'
            style={{ backgroundColor: '#8a88b5' }}
            onClick={() => changeColor("116, 114, 158")}
          >
          </button>
          <button className='theme-button'
            style={{ backgroundColor: '#cf8dab' }}
            onClick={() => changeColor("168, 116, 140")}
          >
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThemeModal;
