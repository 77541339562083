import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ContactsEmailForm from './ContactsEmailForm';
import AddContactForm from './AddContactForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faHeart as faRegularHeart } from '@fortawesome/free-regular-svg-icons';
import { faBriefcase, faLocationDot, faMobileScreen } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import EditContactForm from './EditContactForm';
import { format, parseISO } from 'date-fns';

function formatDate(dateString) {
  const date = parseISO(dateString);
  return format(date, 'MMMM d, yyyy \'at\' h:mm aa');
}

const fetchContacts = async (setContacts, setSelectedContact, query) => {
  const userString = localStorage.getItem('user');
  const user = JSON.parse(userString);
  const authHeaders = {
    'Authorization': `Bearer ${user.access_token}`,
    'client': localStorage.getItem('client'),
    'expiry': localStorage.getItem('expiry'),
    'uid': user.uid,
  };
  try {
    const response = await axios.get(`${process.env.REACT_APP_ROOT_PATH}api/v1/contacts`, {
      headers: authHeaders,
      params: { query } // Include query as a parameter
    });
    const contacts = response.data.contacts;
    setContacts(contacts); // Update the state with the fetched contacts
    if (contacts.length > 0) {
      setSelectedContact(contacts[0]); // Set the first contact as the selected contact
    } else {
      setSelectedContact(null); // Ensure selectedContact is null if no contacts are available
    }
  } catch (error) {
    console.error('Error fetching contacts:', error);
  }
};

const deleteContact = async (contact, setSelectedContact, setContacts) => {
  const userString = localStorage.getItem('user'); 
  const user = JSON.parse(userString);

  const authHeaders = {
    Authorization: `Bearer ${user.access_token}`,
    client: user.provider,
    expiry: user.expires_at,
    uid: user.uid,
  };

  const result = await Swal.fire({
    title: `Delete ${contact.first_name} from contacts?`,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: `Yes, delete!`
  });

  if (result.isConfirmed) {
    try {
      const response = await axios.post(`${process.env.REACT_APP_ROOT_PATH}api/v1/contacts/${contact.id}/delete`, {}, {
        headers: authHeaders
      });
      if (response.status === 200) {
        console.log('Contact deleted:', response.data);
        Swal.fire({
          title: "Deleted!",
          text: "Your email has been deleted.",
          icon: "success"
        });
        setContacts(prevContacts => prevContacts.filter(c => c.id !== contact.id));
        setSelectedContact(null);
      } else {
        console.error(`Failed to delete ${contact.first_name}:`, response.data);
        Swal.fire({
          title: "Error!",
          text: `Failed to delete ${contact.first_name}.`,
          icon: "error"
        });
      }
    } catch (error) {
      console.error('Error deleting contact:', error.response ? error.response.data : error.message);
      Swal.fire({
        title: "Error!",
        text: "An error occurred while deleting the contact.",
        icon: "error"
      });
    }
  }
};

const Contacts = () => {
  const [contacts, setContacts] = useState([]);
  const [query, setQuery] = useState('');
  const [selectedContact, setSelectedContact] = useState(null);
  const [emailContact, setEmailContact] = useState(null); // Separate state for email modal

  useEffect(() => {
    fetchContacts(setContacts, setSelectedContact, query);
  }, [query]);

  const handleSearch = async (event) => {
    event.preventDefault();
    fetchContacts(setContacts, setSelectedContact, query); // Fetch contacts based on the search query
  };

  const handleClick = (contact) => {
    setSelectedContact(contact);
  };

  const openEmailModal = (contact, event) => {
    event.stopPropagation(); // Prevent triggering handleClick
    setEmailContact(contact);
  };

  const closeEmailModal = () => {
    setEmailContact(null);
  };

  return (
    <div className="contacts-container">
      <div className='contacts-row'>
        <div className="contacts-list glass-dark">
          <div className="contacts-header">
            <div className="contacts-search">
              <form onSubmit={handleSearch} className="d-flex justify-content-center">
                <input
                  type="text"
                  className="form-control glass"
                  placeholder="Search contact..."
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </form>
            </div>
            <AddContactForm contact={null} setContacts={setContacts} />
          </div>
          <div className="contacts-cards">
            {contacts.map((contact) => (
              <div className="swipe-container" key={contact.id}>
                <div className="swipe-element" onClick={() => handleClick(contact)}>
                  <div className="contacts-card">
                    <div className="contacts-card-left">
                      {contact.photo_url ? (
                        <img src={contact.photo_url} alt="Contact Avatar" className="contacts-image" />
                      ) : (
                        <img src={"assets/ia-avatar.webp"} alt="Random Avatar" className="contacts-image" />
                      )}
                      <div className="contacts-info">
                        <p className="contact-name">{contact.first_name} {contact.last_name}</p>
                        {contact.company && contact.position ? (
                          <p className="contact-position">{contact.company} - {contact.position}</p>
                        ) : (
                          <p className="contact-position">Company - Position</p>
                        )}
                      </div>
                    </div>
                    <div className="contacts-card-right">
                      <button className="button-sm-dark" onClick={(event) => openEmailModal(contact, event)}>
                        <FontAwesomeIcon icon="fa-solid fa-paper-plane" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className='contacts-show'>
          <div className='contact-show-container glass'>
            {selectedContact ? (
              <div className="contact-details">
                <div className='contact-details-header'>
                  {selectedContact.photo_url ? (
                    <img src={selectedContact.photo_url} alt="Contact Avatar" className="contacts-image" />
                  ) : (
                    <img src={"assets/ia-avatar.webp"} alt="Random Avatar" className="contacts-image" />
                  )}
                  <div className='contact-names'>
                    <h2>{selectedContact.first_name} {selectedContact.last_name}</h2>
                    {selectedContact.company && selectedContact.position && (
                      <p>{selectedContact.company} - {selectedContact.position}</p>
                    )}
                  </div>
                </div>
                <div className='contact-actions'>
                  <button disabled className='button-sm'><FontAwesomeIcon icon={faRegularHeart} /></button>
                  <button className="button-sm" onClick={(event) => openEmailModal(selectedContact, event)}>
                    <FontAwesomeIcon icon="fa-solid fa-paper-plane" />
                  </button>
                  <EditContactForm contact={selectedContact}/>
                  <button className="button-sm" onClick={() => deleteContact(selectedContact, setSelectedContact, setContacts)}><FontAwesomeIcon icon="fa-solid fa-trash" /></button>
                </div>
                <div className='contact-details-divider'>
                  <div className='divider-left'>
                    <p><FontAwesomeIcon className='contact-icon' icon="fa-solid fa-user-tag" />{selectedContact.nickname? selectedContact.nickname : "add nickname" }</p>
                    <p><FontAwesomeIcon className='contact-icon' icon={faEnvelope} /> {selectedContact.email_address}</p>
                    <p><FontAwesomeIcon className='contact-icon' icon={faMobileScreen} /> +33 0139710276</p>
                  </div>
                  <div className='divider-right'>
                    <p><FontAwesomeIcon className='contact-icon' icon={faBriefcase} /> {selectedContact.company}</p>
                    <p><FontAwesomeIcon className='contact-icon' icon={faLocationDot} /> 16 Vla Gaudelet, 75011 Paris, France</p>
                  </div>
                </div>
                <div className='contacts-data'>
                  <p>Number of emails exchanged: {selectedContact.emails.length}</p>
                  <p>Last email exchanged: {selectedContact.emails.length > 0 ? formatDate(selectedContact.emails[selectedContact.emails.length - 1].date_received) : "N/A"}</p>
                </div>
              </div>
            ) : (
              <p style={{color: "var(--secondary-color)"}}>Select a contact to see the details</p>
            )}
          </div>
        </div>
      </div>
      {emailContact && (
        <div className='new-email-form'>
          <ContactsEmailForm contact={emailContact} closeModal={closeEmailModal} />
        </div>
      )}
    </div>
  );
};

export default Contacts;
