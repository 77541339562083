import React, { createContext, useState } from 'react';

const SelectedEmailContext = createContext();

const SelectedEmailProvider = ({ children }) => {
  const [selectedEmail, setSelectedEmail] = useState(null);

  return (
    <SelectedEmailContext.Provider value={{ selectedEmail, setSelectedEmail }}>
      {children}
    </SelectedEmailContext.Provider>
  );
};

export { SelectedEmailContext, SelectedEmailProvider };