import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import SentEmailShow from './SentEmailShow';

const fetchAllEmails = async (user, setEmails, setIsLoading) => {
  const authHeaders = {
    'Authorization': `Bearer ${user.access_token}`,
    'client': localStorage.getItem('client'),
    'expiry': localStorage.getItem('expiry'),
    'uid': user.uid,
  };

  const url = `${process.env.REACT_APP_ROOT_PATH}api/v1/emails/sent`;

  try {
    setIsLoading(true);
    const response = await axios.get(url, { headers: authHeaders });
    const fetchedEmails = response.data.messages || [];
    const cachedEmails = JSON.parse(localStorage.getItem('sentEmails')) || [];
    const areEmailsDifferent = JSON.stringify(fetchedEmails) !== JSON.stringify(cachedEmails);
    if (areEmailsDifferent) {
      setEmails(fetchedEmails);
      localStorage.setItem('sentEmails', JSON.stringify(fetchedEmails));
    }
  } catch (error) {
    console.error('Error fetching emails:', error);
  } finally {
    setIsLoading(false);
  }
};

const fetchNewEmails = async (user, setEmails, setSelectedIndex, selectedIndex) => {
  const authHeaders = {
    'Authorization': `Bearer ${user.access_token}`,
    'client': localStorage.getItem('client'),
    'expiry': localStorage.getItem('expiry'),
    'uid': user.uid,
  };

  const url = `${process.env.REACT_APP_ROOT_PATH}api/v1/emails/new_sent_broadcast`;

  try {
    const response = await axios.get(url, { headers: authHeaders });
    const newEmails = response.data.new_messages || [];
    const storedEmails = JSON.parse(localStorage.getItem('sentEmails')) || [];
    const storedEmailIds = storedEmails.map(email => email.message_id);
    const emailsToAdd = newEmails.filter(email => !storedEmailIds.includes(email.message_id));
    if (emailsToAdd.length > 0) {
      const updatedEmails = [...emailsToAdd, ...storedEmails];
      localStorage.setItem('sentEmails', JSON.stringify(updatedEmails));
      setEmails(updatedEmails);
      setSelectedIndex(selectedIndex + emailsToAdd.length);
    }
  } catch (error) {
    console.error('Error fetching sent emails:', error);
  }
};

const SentEmailsList = ({showCopilot}) => {
  const [emails, setEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [selectedEmailId, setSelectedEmailId] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const emailContainerRef = useRef(null);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return `${months[date.getMonth()]} ${date.getDate()}`;
  };

  useEffect(() => {
    const userString = localStorage.getItem('user');
    if (userString) {
      const user = JSON.parse(userString);
      const cachedEmails = localStorage.getItem('sentEmails');
      if (cachedEmails) {
        setEmails(JSON.parse(cachedEmails));
      } else {
        fetchAllEmails(user, setEmails, setIsLoading);
      }
    }
  }, [setEmails, setIsLoading]);

  useEffect(() => {
    const userString = localStorage.getItem('user');
    if (userString) {
      const user = JSON.parse(userString);
      const interval = setInterval(() => {
        fetchNewEmails(user, setEmails, setSelectedIndex, selectedIndex);
      }, 5000); // Fetch emails every 5 seconds
      return () => clearInterval(interval);
    }
  }, [setEmails, setSelectedIndex, selectedIndex]);

  useEffect(() => {
    const userString = localStorage.getItem('user');
    if (userString) {
      const user = JSON.parse(userString);
      const interval = setInterval(() => {
        fetchAllEmails(user, setEmails, setIsLoading);
      }, 600000); // Fetch emails every 10 minutes
      return () => clearInterval(interval);
    }
  }, [setEmails]);

  const filteredEmails = emails.filter(email => {
    return true;
  }).filter(email => 
    email.to.toLowerCase().includes(query.toLowerCase()) || 
    email.subject.toLowerCase().includes(query.toLowerCase())
  );

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowDown') {
        setSelectedIndex(prevIndex => Math.min(prevIndex + 1, filteredEmails.length - 1));
      } else if (event.key === 'ArrowUp') {
        setSelectedIndex(prevIndex => Math.max(prevIndex - 1, 0));
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [filteredEmails]);

  useEffect(() => {
    if (selectedIndex >= 0 && selectedIndex < filteredEmails.length) {
      setSelectedEmailId(filteredEmails[selectedIndex].message_id);
    }
  }, [selectedIndex, filteredEmails]);

  const handleEmailClick = (emailId, index) => {
    setSelectedEmailId(emailId);
    setSelectedIndex(index);
  };

  const updateEmails = (messageId, changes) => {
    setEmails(prevEmails => {
      let updatedEmails;
      if (changes === null) {
        updatedEmails = prevEmails.filter(email => email.message_id !== messageId);
      } else {
        updatedEmails = prevEmails.map(email =>
          email.message_id === messageId ? { ...email, ...changes } : email
        );
      }
      localStorage.setItem('sentEmails', JSON.stringify(updatedEmails));
      setEmails(updatedEmails);
      return updatedEmails;
    });
  }

  const selectedEmail = filteredEmails.find(email => email.message_id === selectedEmailId);

  return (
    <div className='emailsIndex'>
      <div className='emailslist'>
        <div className='emails-box'>
          <div className="inbox-searchbar">
            <form onSubmit={e => e.preventDefault()} className="inbox-search-form">
              <input
                type="text"
                className="inbox-search-input"
                placeholder="Search Sentbox..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </form>
          </div>
          <div className="email-container hidden-scrollbar" ref={emailContainerRef}>
            {filteredEmails.length > 0 ? filteredEmails.slice(0, 200).map((email, index) => (
              <div
                className={`email ${email.unread ? 'unread' : ''} ${index === selectedIndex ? 'selected' : ''}`}
                key={email.id}
                onClick={() => handleEmailClick(email.message_id, index)}
              >
                <div className="email-info">
                  <p className="email__sender">{email.to}</p>
                </div>
                <p className="email__subject">{email.subject}</p>
                <div className='email__tags'>
                  {email.starred && <p style={{ color: '#ffab00', fontSize: '12px' }}><FontAwesomeIcon icon="fa-solid fa-star" /></p>}
                  {email.attachments && email.attachments.length > 0 && <p style={{ color: '#8b8b8b', fontSize: '12px' }}><FontAwesomeIcon icon="fa-solid fa-paperclip" /></p>}
                  <p className="email__date">{formatDate(email.date)}</p>
                </div>
              </div>
            )) : <p className='no-email'>{isLoading ? "Loading emails..." : "No emails to display." }</p>}
          </div>
        </div>
      </div>
      {selectedEmail ? (
        <SentEmailShow
          email={selectedEmail}
          updateEmails={updateEmails}
          setSelectedEmailId={setSelectedEmailId}
          showCopilot={showCopilot}
        />
      ) : <SentEmailShow isLoading={isLoading}/> }
    </div>
  );
};

export default SentEmailsList;