import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Tooltip } from 'bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import DOMPurify from 'dompurify';

const ForwardForm = ({ email }) => {
  const [subject, setSubject] = useState('');
  const [recipient, setRecipient] = useState('');
  const [emailMessage, setEmailMessage] = useState('');
  const [file, setFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const recipientareaRef = useRef(null);
  const emailMessageRef = useRef(null);

  useEffect(() => {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
    return () => {
      tooltipList.forEach(tooltip => tooltip.dispose());
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'f' && event.altKey === true) {
        if (!showModal) {
          openModal();
        } else {
          closeModal();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [showModal]);

  useEffect(() => {
    if (showModal && recipientareaRef.current) {
      recipientareaRef.current.focus();
    }
  }, [showModal]);

  useEffect(() => {
    setSubject(`Fwd: ${email.subject}`);
    const sanitizedBody = DOMPurify.sanitize(email.body);
    const emailHeader = `---------- Forwarded message ---------<br />
From: ${email.from}<br />
Date: ${email.date}<br />
Subject: ${email.subject}<br />
To: ${email.to}<br /><br />`;
    const formattedBody = email.body.includes('<') ? sanitizedBody : sanitizedBody.replace(/\n/g, '<br />');
    setEmailMessage(`${emailHeader}${formattedBody}`);
  }, [email]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userString = localStorage.getItem('user'); // Retrieve the JSON string
    const user = JSON.parse(userString);

    const authHeaders = {
      'Authorization': `Bearer ${user.access_token}`,
      'Content-Type': 'multipart/form-data',
      'client': localStorage.getItem('client'),
      'expiry': localStorage.getItem('expiry'),
      'uid': user.uid,
    };

    const formData = new FormData();
    formData.append('email[subject]', subject);
    formData.append('email[recipient]', recipient);
    formData.append('email[body]', emailMessage);
    if (file) {
      formData.append('email[file]', file);
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_ROOT_PATH}api/v1/emails`, formData, {
        headers: authHeaders,
      });
      if (response.status === 201) {
        setSubject('');
        setRecipient('');
        setEmailMessage('');
        setFile(null);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Your email has been sent',
          showConfirmButton: false,
          timer: 1500
        });
        setShowModal(false);
      }
    } catch (error) {
      console.error('Error sending email:', error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  const handleImproveEmail = async () => {
    const userString = localStorage.getItem('user'); // Retrieve the JSON string
    const user = JSON.parse(userString);

    const authHeaders = {
      'Authorization': `Bearer ${user.access_token}`,
      'Content-Type': 'application/json',
      'client': localStorage.getItem('client'),
      'expiry': localStorage.getItem('expiry'),
      'uid': user.uid,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_ROOT_PATH}api/v1/emails/improve`, {
        email: { body: emailMessage }
      }, {
        headers: authHeaders,
      });

      if (response.status === 200) {
        setEmailMessage(response.data.body);
      }
    } catch (error) {
      console.error('Error improving email:', error);
    }
  };

  const openModal = () => {
    const modal = document.querySelector('.reply');
    modal.classList.add('showModal');
    setShowModal(true);
  };

  const closeModal = () => {
    const modal = document.querySelector('.reply');
    if (modal) {
      modal.classList.remove('showModal');
      setShowModal(false);
      setEmailMessage('');
    }
  };

  const handleKeyDownOnTextArea = (e) => {
    if (e.key === 'Enter' && e.altKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const handleEmailMessageChange = (e) => {
    setEmailMessage(e.target.innerHTML);
  };
  return (
    <div className='reply'>

      <button className='square-button-gray' onClick={openModal} data-bs-toggle="tooltip" data-bs-placement="top"
        data-bs-custom-class="custom-tooltip" data-bs-title='Forward [Alt + F]'>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.33337 17.5V15.7692C3.33337 14.1017 3.33337 13.2681 3.45448 12.5705C4.12112 8.73042 7.42217 5.71869 11.6312 5.11049C12.3958 5 14.0057 5 15.8334 5" stroke="#101010" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M14.1666 2.5C14.6723 2.99153 16.6666 4.29977 16.6666 5C16.6666 5.70022 14.6723 7.00847 14.1666 7.5" stroke="#101010" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>{/*Froward Icon */}


      </button>
      {showModal && (
        <div className="reply-modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <div className='reply-form-headers'>
              <div className='form-headers-input'>
                <label htmlFor="emailRecipient" style={{ marginRight: '5px' }}>To:</label>
                <input
                  ref={recipientareaRef}
                  id="emailRecipient"
                  type="email"
                  value={recipient}
                  onChange={(e) => setRecipient(e.target.value)}
                  required
                />
              </div>
              <div className='form-headers-input'>
                <label htmlFor="emailSubject" style={{ marginRight: '5px' }}>Subject:</label>
                <input
                  id="emailSubject"
                  type="text"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  required
                />
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div
                ref={emailMessageRef}
                contentEditable
                className="email-message-contenteditable"
                onInput={handleEmailMessageChange}
                dangerouslySetInnerHTML={{ __html: emailMessage }}
                onKeyDown={handleKeyDownOnTextArea}
              />
              <div className='reply-form-actions'>
                <button className='send-button' type="button"><FontAwesomeIcon icon="fa-solid fa-paperclip" /> ATTACH FILE</button>
                <button className='send-button' type="button"><FontAwesomeIcon icon="fa-solid fa-font" /> FORMAT</button>
                <button className='send-button' type="button" onClick={handleImproveEmail}><FontAwesomeIcon icon="fa-solid fa-wand-magic-sparkles" /> IMPROVE WITH AI</button>
                <button className='send-button' type="submit">SEND <FontAwesomeIcon icon="fa-solid fa-paper-plane" /></button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForwardForm;
